export default {
  // #region course
  GET_LIST_USER_BY_COMPANY_REQUEST: 'GET_LIST_USER_BY_COMPANY_REQUEST',
  GET_LIST_USER_BY_COMPANY_SUCCESS: 'GET_LIST_USER_BY_COMPANY_SUCCESS',
  GET_LIST_USER_BY_COMPANY_FAILURE: 'GET_LIST_USER_BY_COMPANY_FAILURE',

  GET_LIST_COMPANY_REQUEST: 'GET_LIST_COMPANY_REQUEST',
  GET_LIST_COMPANY_SUCCESS: 'GET_LIST_COMPANY_SUCCESS',
  GET_LIST_COMPANY_FAILURE: 'GET_LIST_COMPANY_FAILURE',

  GET_LIST_COURSE_REQUEST: 'GET_LIST_COURSE_REQUEST',
  GET_LIST_COURSE_SUCCESS: 'GET_LIST_COURSE_SUCCESS',
  GET_LIST_COURSE_FAILURE: 'GET_LIST_COURSE_FAILURE',

  GET_LIST_LECTURER_REQUEST: 'GET_LIST_LECTURER_REQUEST',
  GET_LIST_LECTURER_SUCCESS: 'GET_LIST_LECTURER_SUCCESS',
  GET_LIST_LECTURER_FAILURE: 'GET_LIST_LECTURER_FAILURE',

  GET_COURSE_STATUS_REQUEST: 'GET_COURSE_STATUS_REQUEST',
  GET_COURSE_STATUS_SUCCESS: 'GET_COURSE_STATUS_SUCCESS',
  GET_COURSE_STATUS_FAILURE: 'GET_COURSE_STATUS_FAILURE',

  GET_COURSE_REQUEST: 'GET_COURSE_REQUEST',
  GET_COURSE_SUCCESS: 'GET_COURSE_SUCCESS',
  GET_COURSE_FAILURE: 'GET_COURSE_FAILURE',

  CREATE_COURSE_REQUEST: 'CREATE_COURSE_REQUEST',
  CREATE_COURSE_SUCCESS: 'CREATE_COURSE_SUCCESS',
  CREATE_COURSE_FAILURE: 'CREATE_COURSE_FAILURE',

  CHECK_SEEN_COURSE_REQUEST: 'CHECK_SEEN_COURSE_REQUEST',
  CHECK_SEEN_COURSE_SUCCESS: 'CHECK_SEEN_COURSE_SUCCESS',
  CHECK_SEEN_COURSE_FAILURE: 'CHECK_SEEN_COURSE_FAILURE',

  UPDATE_COURSE_REQUEST: 'UPDATE_COURSE_REQUEST',
  UPDATE_COURSE_SUCCESS: 'UPDATE_COURSE_SUCCESS',
  UPDATE_COURSE_FAILURE: 'UPDATE_COURSE_FAILURE',

  DELETE_COURSE_REQUEST: 'DELETE_COURSE_REQUEST',
  DELETE_COURSE_SUCCESS: 'DELETE_COURSE_SUCCESS',
  DELETE_COURSE_FAILURE: 'DELETE_COURSE_FAILURE',
  // #endregion

  // #region topic
  GET_LIST_TOPIC_REQUEST: 'GET_LIST_TOPIC_REQUEST',
  GET_LIST_TOPIC_SUCCESS: 'GET_LIST_TOPIC_SUCCESS',
  GET_LIST_TOPIC_FAILURE: 'GET_LIST_TOPIC_FAILURE',

  GET_TOPIC_REQUEST: 'GET_TOPIC_REQUEST',
  GET_TOPIC_SUCCESS: 'GET_TOPIC_SUCCESS',
  GET_TOPIC_FAILURE: 'GET_TOPIC_FAILURE',

  CREATE_TOPIC_REQUEST: 'CREATE_TOPIC_REQUEST',
  CREATE_TOPIC_SUCCESS: 'CREATE_TOPIC_SUCCESS',
  CREATE_TOPIC_FAILURE: 'CREATE_TOPIC_FAILURE',

  UPDATE_TOPIC_REQUEST: 'UPDATE_TOPIC_REQUEST',
  UPDATE_TOPIC_SUCCESS: 'UPDATE_TOPIC_SUCCESS',
  UPDATE_TOPIC_FAILURE: 'UPDATE_TOPIC_FAILURE',

  DELETE_TOPIC_REQUEST: 'DELETE_TOPIC_REQUEST',
  DELETE_TOPIC_SUCCESS: 'DELETE_TOPIC_SUCCESS',
  DELETE_TOPIC_FAILURE: 'DELETE_TOPIC_FAILURE',
  // #endregion

  // #region topic
  GET_LIST_LESSON_REQUEST: 'GET_LIST_LESSON_REQUEST',
  GET_LIST_LESSON_SUCCESS: 'GET_LIST_LESSON_SUCCESS',
  GET_LIST_LESSON_FAILURE: 'GET_LIST_LESSON_FAILURE',

  GET_LESSON_REQUEST: 'GET_LESSON_REQUEST',
  GET_LESSON_SUCCESS: 'GET_LESSON_SUCCESS',
  GET_LESSON_FAILURE: 'GET_LESSON_FAILURE',

  CREATE_LESSON_REQUEST: 'CREATE_LESSON_REQUEST',
  CREATE_LESSON_SUCCESS: 'CREATE_LESSON_SUCCESS',
  CREATE_LESSON_FAILURE: 'CREATE_LESSON_FAILURE',

  UPDATE_LESSON_REQUEST: 'UPDATE_LESSON_REQUEST',
  UPDATE_LESSON_SUCCESS: 'UPDATE_LESSON_SUCCESS',
  UPDATE_LESSON_FAILURE: 'UPDATE_LESSON_FAILURE',

  DELETE_LESSON_REQUEST: 'DELETE_LESSON_REQUEST',
  DELETE_LESSON_SUCCESS: 'DELETE_LESSON_SUCCESS',
  DELETE_LESSON_FAILURE: 'DELETE_LESSON_FAILURE',
  // #endregion

  UPDATE_PERCENT_DETAIL: 'UPDATE_PERCENT_DETAIL',

  UPLOAD_FILE_REQUEST: 'UPLOAD_FILE_REQUEST',
  UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_FAILURE: 'UPLOAD_FILE_FAILURE',
};
