import React from 'react';
// Dashboard
const Dashboard = React.lazy(() => import('./components/Users/Users'));
//.end#Dashboard

// Users
const Users = React.lazy(() => import('./components/Users/Users'));
const UserAdd = React.lazy(() => import('./components/Users/UserAdd'));
const UserDetail = React.lazy(() => import('./components/Users/UserDetail'));
const UserEdit = React.lazy(() => import('./components/Users/UserEdit'));
const UserDelete = React.lazy(() => import('./components/Users/UserDelete'));
const UserChangePassword = React.lazy(() => import('./components/Users/UserChangePassword'));
const ChangePassword = React.lazy(() => import('./components/Users/ChangePassword'));
//.end#Users

// Receiveslip
const Receiveslip = React.lazy(() => import('./components/Receiveslip/Receiveslip'));
const ReceiveslipAdd = React.lazy(() => import('./components/Receiveslip/ReceiveslipAdd'));
const ReceiveslipEdit = React.lazy(() => import('./components/Receiveslip/ReceiveslipEdit'));

const ReceiveslipDetail = React.lazy(() => import('./components/Receiveslip/ReceiveslipDetail'));
//.end#Receiveslip

// UserGroups
const UserGroups = React.lazy(() => import('./components/UserGroups/UserGroups'));
const UserGroupsAdd = React.lazy(() => import('./components/UserGroups/UserGroupsAdd'));
const UserGroupsEdit = React.lazy(() => import('./components/UserGroups/UserGroupsEdit'));
const UserGroupsDetail = React.lazy(() => import('./components/UserGroups/UserGroupsDetail'));
//.end#UsersGroups

//ReceiveType
const ReceiveType = React.lazy(() => import('./components/ReceiveType/ReceiveType'));
const ReceiveTypeAdd = React.lazy(() => import('./components/ReceiveType/ReceiveTypeAdd'));
const ReceiveTypeEdit = React.lazy(() => import('./components/ReceiveType/ReceiveTypeEdit'));
const ReceiveTypeDetail = React.lazy(() => import('./components/ReceiveType/ReceiveTypeDetail'));
//.end#ReceiveType
// FunctionGroups
const FunctionGroups = React.lazy(() => import('./components/FunctionGroups/FunctionGroups'));
const FunctionGroupAdd = React.lazy(() => import('./components/FunctionGroups/FunctionGroupAdd'));
const FunctionGroupDetail = React.lazy(() => import('./components/FunctionGroups/FunctionGroupDetail'));
const FunctionGroupEdit = React.lazy(() => import('./components/FunctionGroups/FunctionGroupEdit'));
const FunctionGroupDelete = React.lazy(() => import('./components/FunctionGroups/FunctionGroupDelete'));
//.end#FunctionGroups

// Function
const Functions = React.lazy(() => import('./components/Functions/Functions'));
const FunctionAdd = React.lazy(() => import('./components/Functions/FunctionAdd'));
const FunctionDetail = React.lazy(() => import('./components/Functions/FunctionDetail'));
const FunctionEdit = React.lazy(() => import('./components/Functions/FunctionEdit'));
const FunctionDelete = React.lazy(() => import('./components/Functions/FunctionDelete'));
//.end#Function

// Menu
const Menus = React.lazy(() => import('./components/Menus/Menus'));
const MenuAdd = React.lazy(() => import('./components/Menus/MenuAdd'));
const MenuDetail = React.lazy(() => import('./components/Menus/MenuDetail'));
const MenuEdit = React.lazy(() => import('./components/Menus/MenuEdit'));
const MenuDelete = React.lazy(() => import('./components/Menus/MenuDelete'));
//.end#Menu

// Business
const Businesses = React.lazy(() => import('./components/Businesses/Businesses'));
const BusinessAdd = React.lazy(() => import('./components/Businesses/BusinessAdd'));
const BusinessDetail = React.lazy(() => import('./components/Businesses/BusinessDetail'));
const BusinessEdit = React.lazy(() => import('./components/Businesses/BusinessEdit'));
//.end#Businesses

// BusinessType
const BusinessesType = React.lazy(() => import('./components/BusinessesType/BusinessesType'));
const BusinessTypeAdd = React.lazy(() => import('./components/BusinessesType/BusinessTypeAdd'));
const BusinessTypeDetail = React.lazy(() => import('./components/BusinessesType/BusinessTypeDetail'));
const BusinessTypeEdit = React.lazy(() => import('./components/BusinessesType/BusinessTypeEdit'));
//.end#BusinessType

// Company
const Companies = React.lazy(() => import('./components/Companies/Companies'));
const CompaniesAdd = React.lazy(() => import('./components/Companies/CompaniesAdd'));
const CompaniesDetail = React.lazy(() => import('./components/Companies/CompaniesDetail'));
// end#Company

//CompanyType
const CompanyType = React.lazy(() => import('./components/CompanyType/CompanyType'));
const CompanyTypeAdd = React.lazy(() => import('./components/CompanyType/CompanyTypeAdd'));
const CompanyTypeEdit = React.lazy(() => import('./components/CompanyType/CompanyTypeEdit'));
const CompanyTypeDetail = React.lazy(() => import('./components/CompanyType/CompanyTypeDetail'));
//.end#CompanyType

// Area
const Area = React.lazy(() => import('./components/Area/Area'));
const AreaAdd = React.lazy(() => import('./components/Area/AreaAdd'));
const AreaDetail = React.lazy(() => import('./components/Area/AreaDetail'));
const AreaEdit = React.lazy(() => import('./components/Area/AreaEdit'));
//.end#Area

// Permissions
const Permissions = React.lazy(() => import('./components/Permissions/Permissions'));
//.end#Permissions

// Store
const Store = React.lazy(() => import('./components/Store/Store'));
const StoreAdd = React.lazy(() => import('./components/Store/StoreAdd'));
const StoreDetail = React.lazy(() => import('./components/Store/StoreDetail'));
const StoreEdit = React.lazy(() => import('./components/Store/StoreEdit'));
//.end#Store

//DepartMent
const DepartMent = React.lazy(() => import('./components/DepartMent/DepartMent'));
const DepartMentAdd = React.lazy(() => import('./components/DepartMent/DepartMentAdd'));
const DepartMentDetail = React.lazy(() => import('./components/DepartMent/DepartMentDetail'));
const DepartMentEdit = React.lazy(() => import('./components/DepartMent/DepartMentEdit'));
//.end#DepartMent

// BusinessUser
const BusinessUser = React.lazy(() => import('./components/BusinessUser/BusinessUser'));
const BusinessUserAdd = React.lazy(() => import('./components/BusinessUser/BusinessUserAdd'));
//.end#BusinessUser

//Customertype
const CustomerType = React.lazy(() => import('./components/CustomerType/CustomerType'));
const CustomerTypeAdd = React.lazy(() => import('./components/CustomerType/CustomerTypeAdd'));
const CustomerTypeDetail = React.lazy(() => import('./components/CustomerType/CustomerTypeDetail'));
const CustomerTypeEdit = React.lazy(() => import('./components/CustomerType/CustomerTypeEdit'));
//.end customertype

//News
const News = React.lazy(() => import('./components/News/News'));
const NewsAdd = React.lazy(() => import('./components/News/NewsAdd'));
const NewsDetail = React.lazy(() => import('./components/News/NewsDetail'));
const NewsEdit = React.lazy(() => import('./components/News/NewsEdit'));
const Comment = React.lazy(() => import('./components/Comment/Comment'));
//.End

// Banner
const Banner = React.lazy(() => import('./components/Banner/Banner'));
const BannerAdd = React.lazy(() => import('./components/Banner/BannerAdd'));
const BannerDetail = React.lazy(() => import('./components/Banner/BannerDetail'));
const BannerEdit = React.lazy(() => import('./components/Banner/BannerEdit'));
//.end#Banner

// BannerType
const BannerType = React.lazy(() => import('./components/BannerType/BannerType'));
const BannerTypeAdd = React.lazy(() => import('./components/BannerType/BannerTypeAdd'));
const BannerTypeDetail = React.lazy(() => import('./components/BannerType/BannerTypeDetail'));
const BannerTypeEdit = React.lazy(() => import('./components/BannerType/BannerTypeEdit'));
//.end#BannerType

// NewsCategory
const NewsCategory = React.lazy(() => import('./components/NewsCategory/NewsCategory'));
const NewsCategoryAdd = React.lazy(() => import('./components/NewsCategory/NewsCategoryAdd'));
const NewsCategoryDetail = React.lazy(() => import('./components/NewsCategory/NewsCategoryDetail'));
const NewsCategoryEdit = React.lazy(() => import('./components/NewsCategory/NewsCategoryEdit'));
//.end#NewsCategory

//contact customer
const ContactCustomer = React.lazy(() => import("./components/ContactCustomer/ContactCustomer"));
// const ContactCustomerAdd = React.lazy(() => import('./components/ContactCustomer/ContactCustomerAdd'));
const ContactCustomerDetail = React.lazy(() =>
  import("./components/ContactCustomer/ContactCustomerDetail")
);
// NewsStatus
const NewsStatus = React.lazy(() => import('./components/NewsStatus/NewsStatus'));
const NewsStatusAdd = React.lazy(() => import('./components/NewsStatus/NewsStatusAdd'));
const NewsStatusDetail = React.lazy(() => import('./components/NewsStatus/NewsStatusDetail'));
const NewsStatusEdit = React.lazy(() => import('./components/NewsStatus/NewsStatusEdit'));
//.end#NewsCategory

// WebsiteCategory
const WebsiteCategory = React.lazy(() => import('./components/WebsiteCategory/WebsiteCategory'));
const WebsiteCategoryAdd = React.lazy(() => import('./components/WebsiteCategory/WebsiteCategoryAdd'));
const WebsiteCategoryDetail = React.lazy(() => import('./components/WebsiteCategory/WebsiteCategoryDetail'));
const WebsiteCategoryEdit = React.lazy(() => import('./components/WebsiteCategory/WebsiteCategoryEdit'));
//.end#WebsiteCategory

// Website
const Website = React.lazy(() => import('./components/Website/Website'));
const WebsiteAdd = React.lazy(() => import('./components/Website/WebsiteAdd'));
const WebsiteDetail = React.lazy(() => import('./components/Website/WebsiteDetail'));
const WebsiteEdit = React.lazy(() => import('./components/Website/WebsiteEdit'));
//.end#Website

// StaticContent
const StaticContent = React.lazy(() => import('./components/StaticContent/StaticContent'));
const StaticContentAdd = React.lazy(() => import('./components/StaticContent/StaticContentAdd'));
const StaticContentDetail = React.lazy(() => import('./components/StaticContent/StaticContentDetail'));
const StaticContentEdit = React.lazy(() => import('./components/StaticContent/StaticContentEdit'));
//.end#StaticContent

//Position
const Position = React.lazy(() => import('./components/Position/Position'));
const PositionAdd = React.lazy(() => import('./components/Position/PositionAdd'));
const PositionEdit = React.lazy(() => import('./components/Position/PositionEdit'));
const PositionDetail = React.lazy(() => import('./components/Position/PositionDetail'));
//.end#Position

//Origin
const Origin = React.lazy(() => import('./components/Origin/Origin'));
const OriginAdd = React.lazy(() => import('./components/Origin/OriginAdd'));
const OriginEdit = React.lazy(() => import('./components/Origin/OriginEdit'));
const OriginDetail = React.lazy(() => import('./components/Origin/OriginDetail'));
//.end#Origin
// CourseStatus
const CourseStatus = React.lazy(() => import('./components/CourseStatus/CourseStatus'));
const CourseStatusAdd = React.lazy(() => import('./components/CourseStatus/CourseStatusAdd'));
const CourseStatusEdit = React.lazy(() => import('./components/CourseStatus/CourseStatusEdit'));
const CourseStatusDetail = React.lazy(() => import('./components/CourseStatus/CourseStatusDetail'));
//.end#CourseStatus
// CourseFeedback
const CourseFeedback = React.lazy(() => import('./components/CourseFeedback/CourseFeedback'));
const CourseFeedbackAdd = React.lazy(() => import('./components/CourseFeedback/CourseFeedbackAdd'));
const CourseFeedbackEdit = React.lazy(() => import('./components/CourseFeedback/CourseFeedbackEdit'));
const CourseFeedbackDetail = React.lazy(() => import('./components/CourseFeedback/CourseFeedbackDetail'));
//.end#PCourseFeedback
//CustomerTypeGroup
const CustomerTypeGroup = React.lazy(() => import('./components/CustomerTypeGroup/CustomerTypeGroup'));
const CustomerTypeGroupAdd = React.lazy(() => import('./components/CustomerTypeGroup/CustomerTypeGroupAdd'));
const CustomerTypeGroupEdit = React.lazy(() => import('./components/CustomerTypeGroup/CustomerTypeGroupEdit'));
const CustomerTypeGroupDetail = React.lazy(() => import('./components/CustomerTypeGroup/CustomerTypeGroupDetail'));
//.end#CustomerTypeGroup

// Coupon
const Coupon = React.lazy(() => import('./components/Coupon/Coupon'));
const CouponAdd = React.lazy(() => import('./components/Coupon/CouponAdd'));
const CouponDetail = React.lazy(() => import('./components/Coupon/CouponDetail'));
const CouponEdit = React.lazy(() => import('./components/Coupon/CouponEdit'));
//.end#Coupon

//PageSetting
const PageSetting = React.lazy(() => import('./components/PageSetting/PageSetting'));
//.end#PageSetting

//Account
const Account = React.lazy(() => import('./components/Account/Account'));
const AccountAdd = React.lazy(() => import('./components/Account/AccountAdd'));
const AccountDetail = React.lazy(() => import('./components/Account/AccountDetail'));
const AccountEdit = React.lazy(() => import('./components/Account/AccountEdit'));
const AccountChangePassword = React.lazy(() => import('./components/Account/AccountChangePassword'));
const AccChangePassword = React.lazy(() => import('./components/Account/AccChangePassword'));
const AccountPassword = React.lazy(() => import('./components/Account/AccountPassword'));

//.End

// #region course
const TopicPage = React.lazy(() => import('components/Training/pages/TopicPage'));
const TopicCreateEditPage = React.lazy(() => import('components/Training/pages/TopicCreateEditPage'));

// Teacher
const Teacher = React.lazy(() => import('./components/Teacher/Teacher')); 
const TeacherAdd = React.lazy(() => import('./components/Teacher/TeacherAdd'));
const TeacherDetail = React.lazy(() => import('./components/Teacher/TeacherDetail'));
const TeacherEdit = React.lazy(() => import('./components/Teacher/TeacherEdit'));
const TeacherChangePassword = React.lazy(() => import('./components/Teacher/TeacherChangePassword'));
//.end#Teacher
const LessonCreateEditPage = React.lazy(() => import('components/Training/pages/LessonCreateEditPage'));
const LessonPage = React.lazy(() => import('components/Training/pages/LessonPage'));

// #endregion
// Price
const Price = React.lazy(() => import("./components/Price/Price"));
const PriceAdd = React.lazy(() => import("./components/Price/PriceAdd"));
const PriceEdit = React.lazy(() => import("./components/Price/PriceEdit"));
const PriceDetail = React.lazy(() => import("./components/Price/PriceDetail"));
//.end#Price

// TrainingTopic
const TrainingTopic = React.lazy(() => import('./components/TrainingTopic/TrainingTopic'));
const TrainingTopicAdd = React.lazy(() => import('./components/TrainingTopic/TrainingTopicAdd'));
const TrainingTopicEdit = React.lazy(() => import('./components/TrainingTopic/TrainingTopicEdit'));
const TrainingTopicDetail = React.lazy(() => import('./components/TrainingTopic/TrainingTopicDetail'));
//.end#TrainingTopic

// Question
const Question = React.lazy(() => import('./components/Question/Question'));
const QuestionAdd = React.lazy(() => import('./components/Question/QuestionAdd'));
const QuestionEdit = React.lazy(() => import('./components/Question/QuestionEdit'));
const QuestionDetail = React.lazy(() => import('./components/Question/QuestionDetail'));
//.end#Question

// test
const TrainingTest = React.lazy(() => import('./components/TrainingTest/TrainingTest'));
const TrainingTestAdd = React.lazy(() => import('./components/TrainingTest/TrainingTestAdd'));
const TrainingTestEdit = React.lazy(() => import('./components/TrainingTest/TrainingTestEdit'));
const TrainingTestDetail = React.lazy(() => import('./components/TrainingTest/TrainingTestDetail'));
const TrainingTestCopy = React.lazy(() => import('./components/TrainingTest/TrainingTestCopy'));

//.end#Test

// test of user
const TrainingTestUser = React.lazy(() => import('./components/TrainingTestUser/TrainingTestUser'));
const TrainingTestUserDoing = React.lazy(() => import('./components/TrainingTestUser/TrainingTestUserDoing'));
const TrainingTestUserEdit = React.lazy(() => import('./components/TrainingTestUser/TrainingTestUserEdit'));

//.end#Testofusser

// result of test
const TrainingTestResult = React.lazy(() => import('./components/TrainingTestResult/TrainingTestResult'));
const TrainingTestResultReview = React.lazy(() => import('./components/TrainingTestResult/TrainingTestResultReview'));
const TrainingTestResultDetail = React.lazy(() => import('./components/TrainingTestResult/TrainingTestResultDetail'));

// GoldenBoard
const GoldenBoard = React.lazy(() => import('./components/GoldenBoard/GoldenBoard'));
const GoldenBoardDetail = React.lazy(() => import('./components/GoldenBoard/GoldenBoardDetail'));
//.end#GoldenBoard

// PostTopic
const PostTopic = React.lazy(() => import('./components/PostTopic/PostTopic'));
const PostTopicDetail = React.lazy(() => import('./components/PostTopic/PostTopicDetail'));
const PostTopicReview = React.lazy(() => import('./components/PostTopic/PostTopicReview'));
//.end#PostTopic

// PostComment
const PostComment = React.lazy(() => import('./components/PostComment/PostComment'));
const PostCommentList = React.lazy(() => import('./components/PostComment/PostCommentList'));
const PostCommentReply = React.lazy(() => import('./components/PostComment/PostCommentReply'));

//.end#PostComment


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config

const CoursePage = React.lazy(() => import('components/Training/pages/CoursePage'));
const CourseCreateEditPage = React.lazy(() => import('components/Training/pages/CourseCreateEditPage'));

const ForumTopicAddPage = React.lazy(() => import('components/ForumTopic/page/ForumTopicAddPage'));
const ForumTopicPage = React.lazy(() => import('components/ForumTopic/page/ForumTopicPage'));

// ReceiptPaymentReviewLevel
const ReceiptPaymentRL = React.lazy(() => import("./components/ReceiptPaymentRL/ReceiptPaymentRL"))
const ReceiptPaymentRLAdd = React.lazy(() => import("./components/ReceiptPaymentRL/ReceiptPaymentRLAdd"))
const ReceiptPaymentRLDetail = React.lazy(() => import("./components/ReceiptPaymentRL/ReceiptPaymentRLDetail"))
const ReceiptPaymentRLEdit = React.lazy(() => import("./components/ReceiptPaymentRL/ReceiptPaymentRLEdit"))
//.end#ReceiptPaymentReviewLevel

// ReceiptPaymentReviewLevel
const BusinessCustomer = React.lazy(() => import("./components/BusinessCustomer/BusinessCustomer"))
const BusinessCustomerAdd = React.lazy(() => import("./components/BusinessCustomer/BusinessCustomerAdd"))
const BusinessCustomerDetail = React.lazy(() => import("./components/BusinessCustomer/BusinessCustomerDetail"))
const BusinessCustomerEdit = React.lazy(() => import("./components/BusinessCustomer/BusinessCustomerEdit"))
//.end#ReceiptPaymentReviewLevel

//FAQs
const FAQs = React.lazy(() => import('./components/FAQs/FAQs'));
const FAQsAdd = React.lazy(() => import('./components/FAQs/FAQsAdd'));
const FAQsDetail = React.lazy(() => import('./components/FAQs/FAQsDetail'));
const FAQsEdit = React.lazy(() => import('./components/FAQs/FAQsEdit'));
//.end#FAQs

// Support
const Support = React.lazy(() => import('./components/Support/Support'));
const SupportDetail = React.lazy(() => import('./components/Support/SupportDetail'));
const SupportEdit = React.lazy(() => import('./components/Support/SupportEdit'));
//.end#Support

// Admin Website: Topic
const Topic = React.lazy(() => import("./components/Topic/Topic"))
const TopicAdd = React.lazy(() => import("./components/Topic/TopicAdd"))
const TopicDetail = React.lazy(() => import("./components/Topic/TopicDetail"))
const TopicEdit = React.lazy(() => import("./components/Topic/TopicEdit"))
//.End Topic
// Price lesson
const PriceLesson = React.lazy(() => import("./components/PriceLesson/PriceLesson"));
const PriceLessonAdd = React.lazy(() => import("./components/PriceLesson/PriceLessonAdd"));
const PriceLessonEdit = React.lazy(() => import("./components/PriceLesson/PriceLessonEdit"));
const PriceLessonDetail = React.lazy(() => import("./components/PriceLesson/PriceLessonDetail"));
//.end#Price lesson

const routes = [
  {
    path: '/',
    exact: true,
    name: 'Trang chủ',
    any: true,
    function: 'DASHBOARD_VIEW',
    component: () => {
      window._$g.rdr('/dashboard');
      return null;
    },
  },
  //Dashboard
  {
    path: '/dashboard',
    exact: true,
    name: 'Dashboard',
    function: 'DASHBOARD',
    any: true,
    component: Dashboard,
  },
  //.end#dashboard
  // Users
  {
    path: '/users',
    exact: true,
    name: 'Danh sách nhân viên',
    function: 'SYS_USER_VIEW',
    component: Users,
  },
  {
    path: '/users/add',
    exact: true,
    name: 'Thêm mới',
    function: 'SYS_USER_ADD',
    component: UserAdd,
  },
  {
    path: '/users/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'SYS_USER_VIEW',
    component: UserDetail,
  },
  {
    path: '/users/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'SYS_USER_EDIT',
    component: UserEdit,
  },
  {
    path: '/users/delete/:id',
    exact: true,
    name: 'Xóa',
    function: 'SYS_USER_DEL',
    component: UserDelete,
  },
  {
    path: '/users/change-password/:id',
    exact: true,
    name: 'Thay đổi mật khẩu',
    function: 'SYS_USER_PASSWORD',
    component: UserChangePassword,
  },
  {
    path: '/change-password',
    exact: true,
    name: 'Thay đổi mật khẩu',
    function: null,
    component: ChangePassword,
  },
  //.end#Users

  //UserGroup
  {
    path: '/user-groups',
    exact: true,
    name: 'Danh sách nhóm người dùng',
    function: 'SYS_USERGROUP_VIEW',
    component: UserGroups,
  },
  {
    path: '/user-groups/add',
    exact: true,
    name: 'Thêm mới',
    function: 'SYS_USERGROUP_ADD',
    component: UserGroupsAdd,
  },
  {
    path: '/user-groups/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'SYS_USERGROUP_VIEW',
    component: UserGroupsDetail,
  },
  {
    path: '/user-groups/delete/:id',
    exact: true,
    name: 'Xóa',
    function: 'SYS_USERGROUP_DEL',
  },
  {
    path: '/user-groups/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'SYS_USERGROUP_EDIT',
    component: UserGroupsEdit,
  },
  //.end#UserGroup

  //FunctionGroups
  {
    path: '/function-groups',
    exact: true,
    name: 'Danh sách nhóm quyền',
    function: 'SYS_FUNCTIONGROUP_VIEW',
    component: FunctionGroups,
  },
  {
    path: '/function-groups/add',
    exact: true,
    name: 'Thêm mới',
    function: 'SYS_FUNCTIONGROUP_ADD',
    component: FunctionGroupAdd,
  },
  {
    path: '/function-groups/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'SYS_FUNCTIONGROUP_EDIT',
    component: FunctionGroupEdit,
  },
  {
    path: '/function-groups/delete/:id',
    exact: true,
    name: 'Xóa',
    function: 'SYS_FUNCTIONGROUP_DEL',
    component: FunctionGroupDelete,
  },
  {
    path: '/function-groups/details/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'SYS_FUNCTIONGROUP_VIEW',
    component: FunctionGroupDetail,
  },
  //.end#FunctionGroups
  //CourseStatus
  {
    path: '/course-status',
    exact: true,
    name: 'Danh sách trạng thái khóa học',
    function: 'MD_COURSESTATUS_VIEW',
    component: CourseStatus,
  },
  {
    path: '/course-status/add',
    exact: true,
    name: 'Thêm mới',
    function: 'MD_COURSESTATUS_ADD',
    component: CourseStatusAdd,
  },
  {
    path: '/course-status/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'MD_COURSESTATUS_VIEW',
    component: CourseStatusDetail,
  },
  {
    path: '/course-status/delete/:id',
    exact: true,
    name: 'Xóa',
    function: 'MD_COURSESTATUS_DEL',
  },
  {
    path: '/course-status/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'MD_COURSESTATUS_EDIT',
    component: CourseStatusEdit,
  },
  //.end#CourseStatus

  //CourseFeedback
  {
    path: '/course-feedback',
    exact: true,
    name: 'Danh sách đánh giá khóa học',
    function: 'TR_COURSE_FEEDBACK_VIEW',
    component: CourseFeedback,
  },
  {
    path: '/course-feedback/add',
    exact: true,
    name: 'Thêm mới',
    function: 'TR_COURSE_FEEDBACK_ADD',
    component: CourseFeedbackAdd,
  },
  {
    path: '/course-feedback/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'TR_COURSE_FEEDBACK_VIEW',
    component: CourseFeedbackDetail,
  },
  {
    path: '/course-feedback/delete/:id',
    exact: true,
    name: 'Xóa',
    function: 'TR_COURSE_FEEDBACK_DEL',
  },
  {
    path: '/course-feedback/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'TR_COURSE_FEEDBACK_EDIT',
    component: CourseFeedbackEdit,
  },
  //.end#CourseFeedback

  //Functions
  {
    path: '/functions',
    exact: true,
    name: 'Danh sách quyền',
    function: 'SYS_FUNCTION_VIEW',
    component: Functions,
  },
  {
    path: '/functions/add',
    exact: true,
    name: 'Thêm mới',
    function: 'SYS_FUNCTION_ADD',
    component: FunctionAdd,
  },
  {
    path: '/functions/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'SYS_FUNCTION_EDIT',
    component: FunctionEdit,
  },
  {
    path: '/functions/delete/:id',
    exact: true,
    name: 'Xóa',
    function: 'SYS_FUNCTION_DEL',
    component: FunctionDelete,
  },
  {
    path: '/functions/details/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'SYS_FUNCTION_VIEW',
    component: FunctionDetail,
  },
  //.end#Functions

  //Menus
  {
    path: '/menus',
    exact: true,
    name: 'Danh sách menu',
    function: 'SYS_MENU_VIEW',
    component: Menus,
  },
  {
    path: '/menus/add',
    exact: true,
    name: 'Thêm mới',
    function: 'SYS_MENU_ADD',
    component: MenuAdd,
  },
  {
    path: '/menus/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'SYS_MENU_EDIT',
    component: MenuEdit,
  },
  {
    path: '/menus/delete/:id',
    exact: true,
    name: 'Xóa',
    function: 'SYS_MENU_DEL',
    component: MenuDelete,
  },
  {
    path: '/menus/details/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'SYS_MENU_VIEW',
    component: MenuDetail,
  },
  //.end#Menus

  // Business
  {
    path: '/businesses',
    exact: true,
    name: 'Danh sách cơ sở',
    function: 'AM_BUSINESS_VIEW',
    component: Businesses,
  },
  {
    path: '/businesses/add',
    exact: true,
    name: 'Thêm mới',
    function: 'AM_BUSINESS_ADD',
    component: BusinessAdd,
  },
  {
    path: '/businesses/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'AM_BUSINESS_VIEW',
    component: BusinessDetail,
  },
  {
    path: '/businesses/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'AM_BUSINESS_EDIT',
    component: BusinessEdit,
  },
  //.end#Business

  // BusinessType
  {
    path: '/businesses-type',
    exact: true,
    name: 'Danh sách loại cơ sở',
    function: 'AM_BUSINESSTYPE_VIEW',
    component: BusinessesType,
  },
  {
    path: '/businesses-type/add',
    exact: true,
    name: 'Thêm mới',
    function: 'AM_BUSINESSTYPE_ADD',
    component: BusinessTypeAdd,
  },
  {
    path: '/businesses-type/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'AM_BUSINESSTYPE_VIEW',
    component: BusinessTypeDetail,
  },
  {
    path: '/businesses-type/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'AM_BUSINESSTYPE_EDIT',
    component: BusinessTypeEdit,
  },
  //.end#BusinessType

  // Companies
  {
    path: '/companies',
    exact: true,
    name: 'Danh sách công ty',
    function: 'AM_COMPANY_VIEW',
    component: Companies,
  },
  {
    path: '/companies/add',
    exact: true,
    name: 'Thêm mới',
    function: 'AM_COMPANY_ADD',
    component: CompaniesAdd,
  },
  {
    path: '/companies/details/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'AM_COMPANY_VIEW',
    component: CompaniesDetail,
  },
  {
    path: '/companies/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'AM_COMPANY_EDIT',
    component: CompaniesAdd,
  },
  // .end#Companies

  // CompanyType
  {
    path: '/company-type',
    exact: true,
    name: 'Danh sách loại hình công ty',
    function: 'AM_COMPANYTYPE_VIEW',
    component: CompanyType,
  },
  {
    path: '/company-type/add',
    exact: true,
    name: 'Thêm mới',
    function: 'AM_COMPANYTYPE_ADD',
    component: CompanyTypeAdd,
  },
  {
    path: '/company-type/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'AM_COMPANYTYPE_VIEW',
    component: CompanyTypeDetail,
  },
  {
    path: '/company-type/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'AM_COMPANYTYPE_EDIT',
    component: CompanyTypeEdit,
  },
  // .end#CompanyType

  //Permissions
  {
    path: '/permissions',
    exact: true,
    name: 'Phân quyền',
    function: 'PERMISSION_VIEW',
    component: Permissions,
  },
  //.end#permissions
  {
    path: "/contact",
    exact: true,
    name: "Danh sách liên hệ",
    function: "CMS_CONTACT_VIEW",
    component: ContactCustomer,
  },
  // { path: '/contact/add', exact: true, name: 'Thêm mới', function: 'MD_CONTACT_CUSTOMER_ADD', component: ContactCustomerAdd },
  {
    path: "/contact/detail/:id",
    exact: true,
    name: "Chi tiết",
    function: "CMS_CONTACT_VIEW",
    component: ContactCustomerDetail,
  },
  // Area
  {
    path: '/area',
    exact: true,
    name: 'Danh sách khu vực',
    function: 'MD_AREA_VIEW',
    component: Area,
  },
  {
    path: '/area/add',
    exact: true,
    name: 'Thêm mới',
    function: 'MD_AREA_ADD',
    component: AreaAdd,
  },
  {
    path: '/area/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'MD_AREA_VIEW',
    component: AreaDetail,
  },
  {
    path: '/area/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'MD_AREA_EDIT',
    component: AreaEdit,
  },
  //.end#Area

  // Store
  {
    path: '/store',
    exact: true,
    name: 'Danh sách văn phòng',
    function: 'MD_STORE_VIEW',
    component: Store,
  },
  {
    path: '/store/add',
    exact: true,
    name: 'Thêm mới',
    function: 'MD_STORE_ADD',
    component: StoreAdd,
  },
  {
    path: '/store/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'MD_STORE_VIEW',
    component: StoreDetail,
  },
  {
    path: '/store/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'MD_STORE_EDIT',
    component: StoreEdit,
  },
  //.end#Store

  //DepartMent
  {
    path: '/department',
    exact: true,
    name: 'Danh sách phòng ban',
    function: 'MD_DEPARTMENT_VIEW',
    component: DepartMent,
  },
  {
    path: '/department/add',
    exact: true,
    name: 'Thêm mới',
    function: 'MD_DEPARTMENT_ADD',
    component: DepartMentAdd,
  },
  {
    path: '/department/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'MD_DEPARTMENT_VIEW',
    component: DepartMentDetail,
  },
  {
    path: '/department/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'MD_DEPARTMENT_EDIT',
    component: DepartMentEdit,
  },
  //.end#DepartMent

  //Business User
  {
    path: '/business-user',
    exact: true,
    name: 'Phân nhân viên - Cơ sở',
    function: 'SYS_BUSINESS_USER_VIEW',
    component: BusinessUser,
  },
  {
    path: '/business-user/add',
    exact: true,
    name: 'Thêm nhân viên vào cơ sở',
    function: 'SYS_BUSINESS_USER_ADD',
    component: BusinessUserAdd,
  },
  //.end#Business User

  //CustomerType
  {
    path: '/customer-type',
    exact: true,
    name: 'Loại khách hàng',
    function: 'CRM_CUSTOMERTYPE_VIEW',
    component: CustomerType,
  },
  {
    path: '/customer-type/add',
    exact: true,
    name: 'Thêm mới',
    function: 'CRM_CUSTOMERTYPE_ADD',
    component: CustomerTypeAdd,
  },
  {
    path: '/customer-type/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'CRM_CUSTOMERTYPE_VIEW',
    component: CustomerTypeDetail,
  },
  {
    path: '/customer-type/update/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'CRM_CUSTOMERTYPE_EDIT',
    component: CustomerTypeEdit,
  },
  //.end CustomerType

  //News
  {
    path: '/news',
    exact: true,
    name: 'Danh sách blog',
    function: 'NEWS_NEWS_VIEW',
    component: News,
  },
  {
    path: '/news/add',
    exact: true,
    name: 'Thêm mới',
    function: 'NEWS_NEWS_ADD',
    component: NewsAdd,
  },
  {
    path: '/news/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'NEWS_NEWS_VIEW',
    component: NewsDetail,
  },
  {
    path: '/news/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'NEWS_NEWS_EDIT',
    component: NewsEdit,
  },
  //.End
  //Comment
  {
    path: '/comment',
    exact: true,
    name: 'Danh sách bình luận',
    function: 'PRO_COMMENT_VIEW',
    component: Comment,
  },
  //.end#Comment
  // Banner
  {
    path: '/banner',
    exact: true,
    name: 'Banner',
    function: 'CMS_BANNER_VIEW',
    component: Banner,
  },
  {
    path: '/banner/add',
    exact: true,
    name: 'Thêm mới',
    function: 'CMS_BANNER_ADD',
    component: BannerAdd,
  },
  {
    path: '/banner/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'CMS_BANNER_VIEW',
    component: BannerDetail,
  },
  {
    path: '/banner/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'CMS_BANNER_EDIT',
    component: BannerEdit,
  },
  //.end#BannerType

  // BannerType
  {
    path: '/banner-type',
    exact: true,
    name: 'Loại banner',
    function: 'CMS_BANNERTYPE_VIEW',
    component: BannerType,
  },
  {
    path: '/banner-type/add',
    exact: true,
    name: 'Thêm mới',
    function: 'CMS_BANNERTYPE_ADD',
    component: BannerTypeAdd,
  },
  {
    path: '/banner-type/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'CMS_BANNERTYPE_VIEW',
    component: BannerTypeDetail,
  },
  {
    path: '/banner-type/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'CMS_BANNERTYPE_EDIT',
    component: BannerTypeEdit,
  },
  //.end#BannerType

  // NewsCategory
  {
    path: '/news-category',
    exact: true,
    name: 'Chuyên mục blog',
    function: 'NEWS_NEWSCATEGORY_VIEW',
    component: NewsCategory,
  },
  {
    path: '/news-category/add',
    exact: true,
    name: 'Thêm mới',
    function: 'NEWS_NEWSCATEGORY_ADD',
    component: NewsCategoryAdd,
  },
  {
    path: '/news-category/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'NEWS_NEWSCATEGORY_VIEW',
    component: NewsCategoryDetail,
  },
  {
    path: '/news-category/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'NEWS_NEWSCATEGORY_EDIT',
    component: NewsCategoryEdit,
  },
  //.end#NewsCategory

  // NewsStatus
  {
    path: '/news-status',
    exact: true,
    name: 'Trạng thái tin tức',
    function: 'NEWS_NEWSSTATUS_VIEW',
    component: NewsStatus,
  },
  {
    path: '/news-status/add',
    exact: true,
    name: 'Thêm mới',
    function: 'NEWS_NEWSSTATUS_ADD',
    component: NewsStatusAdd,
  },
  {
    path: '/news-status/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'NEWS_NEWSSTATUS_VIEW',
    component: NewsStatusDetail,
  },
  {
    path: '/news-status/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'NEWS_NEWSSTATUS_EDIT',
    component: NewsStatusEdit,
  },
  //.end#NewsStatus

  // WebsiteCategory
  {
    path: '/website-category',
    exact: true,
    name: 'Danh mục website',
    function: 'CMS_WEBSITECATE_VIEW',
    component: WebsiteCategory,
  },
  {
    path: '/website-category/add',
    exact: true,
    name: 'Thêm mới',
    function: 'CMS_WEBSITECATE_ADD',
    component: WebsiteCategoryAdd,
  },
  {
    path: '/website-category/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'CMS_WEBSITECATE_VIEW',
    component: WebsiteCategoryDetail,
  },
  {
    path: '/website-category/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'CMS_WEBSITECATE_EDIT',
    component: WebsiteCategoryEdit,
  },
  //.end#WebsiteCategory

  // Website
  {
    path: '/website',
    exact: true,
    name: 'Danh sách website',
    function: 'CMS_WEBSITE_VIEW',
    component: Website,
  },
  {
    path: '/website/add',
    exact: true,
    name: 'Thêm mới',
    function: 'CMS_WEBSITE_ADD',
    component: WebsiteAdd,
  },
  {
    path: '/website/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'CMS_WEBSITE_VIEW',
    component: WebsiteDetail,
  },
  {
    path: '/website/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'CMS_WEBSITE_EDIT',
    component: WebsiteEdit,
  },
  //.end#Website

  // StaticContent
  {
    path: '/static-content',
    exact: true,
    name: 'Nội dung trang tĩnh',
    function: 'CMS_STATICCONTENT_VIEW',
    component: StaticContent,
  },
  {
    path: '/static-content/add',
    exact: true,
    name: 'Thêm mới',
    function: 'CMS_STATICCONTENT_ADD',
    component: StaticContentAdd,
  },
  {
    path: '/static-content/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'CMS_STATICCONTENT_VIEW',
    component: StaticContentDetail,
  },
  {
    path: '/static-content/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'CMS_STATICCONTENT_EDIT',
    component: StaticContentEdit,
  },
  //.end#StaticContent

  // Position
  {
    path: '/position',
    exact: true,
    name: 'Danh sách chức vụ',
    function: 'MD_POSITION_VIEW',
    component: Position,
  },
  {
    path: '/position/add',
    exact: true,
    name: 'Thêm mới',
    function: 'MD_POSITION_ADD',
    component: PositionAdd,
  },
  {
    path: '/position/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'MD_POSITION_VIEW',
    component: PositionDetail,
  },
  {
    path: '/position/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'MD_POSITION_EDIT',
    component: PositionEdit,
  },
  // .end#Position

  // Origin
  {
    path: '/origin',
    exact: true,
    name: 'Danh sách xuất xứ',
    function: 'MD_ORIGIN_VIEW',
    component: Origin,
  },
  {
    path: '/origin/add',
    exact: true,
    name: 'Thêm mới',
    function: 'MD_ORIGIN_ADD',
    component: OriginAdd,
  },
  {
    path: '/origin/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'MD_ORIGIN_VIEW',
    component: OriginDetail,
  },
  {
    path: '/origin/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'MD_ORIGIN_EDIT',
    component: OriginEdit,
  },
  // .end#Origin

  // CustomerTypeGroup
  {
    path: '/customer-type-group',
    exact: true,
    name: 'Danh sách nhóm loại khách hàng',
    function: 'CRM_CUSTOMERTYPE_GROUP_VIEW',
    component: CustomerTypeGroup,
  },
  {
    path: '/customer-type-group/add',
    exact: true,
    name: 'Thêm mới',
    function: 'CRM_CUSTOMERTYPE_GROUP_ADD',
    component: CustomerTypeGroupAdd,
  },
  {
    path: '/customer-type-group/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'CRM_CUSTOMERTYPE_GROUP_VIEW',
    component: CustomerTypeGroupDetail,
  },
  {
    path: '/customer-type-group/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'CRM_CUSTOMERTYPE_GROUP_EDIT',
    component: CustomerTypeGroupEdit,
  },
  // .end#CustomerTypeGroup

  //Coupon
  {
    path: '/coupon',
    exact: true,
    name: 'Mã Khuyến mãi',
    function: 'SM_COUPON_VIEW',
    component: Coupon,
  },
  {
    path: '/coupon/add',
    exact: true,
    name: 'Thêm mới',
    function: 'SM_COUPON_ADD',
    component: CouponAdd,
  },
  {
    path: '/coupon/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'SM_COUPON_VIEW',
    component: CouponDetail,
  },
  {
    path: '/coupon/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'SM_COUPON_EDIT',
    component: CouponEdit,
  },
  // end#Coupon

  // PageSetting
  {
    path: '/page-setting',
    exact: true,
    name: 'Cài đặt trang web',
    function: 'SYS_APPCONFIG_VIEW',
    component: PageSetting,
  },
  // end#PageSeting
  // ReceiveType
  {
    path: '/receive-type',
    exact: true,
    name: 'Danh sách loại thu',
    function: 'MD_RECEIVETYPE_VIEW',
    component: ReceiveType,
  },
  {
    path: '/receive-type/add',
    exact: true,
    name: 'Thêm mới',
    function: 'MD_RECEIVETYPE_ADD',
    component: ReceiveTypeAdd,
  },
  {
    path: '/receive-type/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'MD_RECEIVETYPE_VIEW',
    component: ReceiveTypeDetail,
  },
  {
    path: '/receive-type/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'MD_RECEIVETYPE_EDIT',
    component: ReceiveTypeEdit,
  },
  // .end#ReceiveType
  // Admin Website: Account
  {
    path: '/account',
    exact: true,
    name: 'Danh sách học viên',
    function: 'CRM_ACCOUNT_VIEW',
    component: Account,
  },
  {
    path: '/account/add',
    exact: true,
    name: 'Thêm mới',
    function: 'CRM_ACCOUNT_ADD',
    component: AccountAdd,
  },
  {
    path: '/account/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'CRM_ACCOUNT_VIEW',
    component: AccountDetail,
  },
  {
    path: '/account/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'CRM_ACCOUNT_EDIT',
    component: AccountEdit,
  },
  {
    path: '/account/account-change-password/:id',
    exact: true,
    name: 'Thay đổi mật khẩu',
    function: 'SYS_ACCOUNT_PASSWORD',
    component: AccountChangePassword,
  },

  {
    path: '/account/password/:id',
    exact: true,
    name: 'Cấp lại mật khẩu',
    function: 'CRM_ACCOUNT_PASSWORD',
    component: AccountPassword,
  },

  // Receiveslip
  {
    path: '/receiveslip',
    exact: true,
    name: 'Danh sách phiếu thu',
    function: 'SL_RECEIVESLIP_VIEW',
    component: Receiveslip,
  },
  {
    path: '/receiveslip/add',
    exact: true,
    name: 'Thêm mới',
    function: 'SL_RECEIVESLIP_ADD',
    component: ReceiveslipAdd,
  },
  {
    path: '/receiveslip/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'SL_RECEIVESLIP_ADD',
    component: ReceiveslipEdit,
  },
  {
    path: '/receiveslip/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'SL_RECEIVESLIP_VIEW',
    component: ReceiveslipDetail,
  },
  // .end#Receiveslip
  {
    path: '/acc-change-password/:id',
    exact: true,
    name: 'Cấp lại mật khẩu',
    function: null,
    component: AccChangePassword,
  },

  // Teacher
  {
    path: '/teacher',
    exact: true,
    name: 'Danh sách giảng viên',
    function: 'MD_TEACHER_VIEW',
    component: Teacher,
  },
  {
    path: '/teacher/add',
    exact: true,
    name: 'Thêm mới',
    function: 'MD_TEACHER_ADD',
    component: TeacherAdd,
  },
  {
    path: '/teacher/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'MD_TEACHER_VIEW',
    component: TeacherDetail,
  },
  {
    path: '/teacher/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'MD_TEACHER_EDIT',
    component: TeacherEdit,
  },
  {
    path: '/teacher/change-password/:id',
    exact: true,
    name: 'Thay đổi mật khẩu',
    function: 'MD_TEACHER_PASSWORD',
    component: TeacherChangePassword,
  },
  //.end#Teacher
  // Admin Website: Account
  //.End
  {
    path: '/lesson-course',
    exact: true,
    name: 'Quản lý bài giảng',
    function: 'TR_LESSON_COURSE_VIEW',
    component: LessonPage,
  },
  {
    path: '/lesson-course/add',
    exact: true,
    name: 'Thêm bài giảng',
    function: 'TR_LESSON_COURSE_ADD',
    component: LessonCreateEditPage,
  },
  {
    path: '/lesson-course/edit/:lesson_id',
    exact: true,
    name: 'Sửa bài giảng',
    function: 'TR_LESSON_COURSE_EDIT',
    component: LessonCreateEditPage,
  },
  {
    path: '/lesson-course/view/:lesson_id',
    exact: true,
    name: 'Xem bài giảng',
    function: 'TR_LESSON_COURSE_VIEW',
    component: LessonCreateEditPage,
  },
  //.End
  //TrainingTopic
  {
    path: '/training-topic',
    exact: true,
    name: 'Danh sách chủ đề đào tạo',
    function: 'TR_TRAININGTOPIC_VIEW',
    component: TrainingTopic,
  },
  {
    path: '/training-topic/add',
    exact: true,
    name: 'Thêm mới',
    function: 'TR_TRAININGTOPIC_ADD',
    component: TrainingTopicAdd,
  },
  {
    path: '/training-topic/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'TR_TRAININGTOPIC_VIEW',
    component: TrainingTopicDetail,
  },
  {
    path: '/training-topic/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'TR_TRAININGTOPIC_EDIT',
    component: TrainingTopicEdit,
  },
  //.end #TrainingTopic
  // #region topiccourse
  {
    path: '/topic-course',
    exact: true,
    name: 'Quản lý chủ đề',
    function: 'TR_TOPIC_COURSE_VIEW',
    component: TopicPage,
  },
  {
    path: '/topic-course/add',
    exact: true,
    name: 'Thêm chủ đề',
    function: 'TR_TOPIC_COURSE_ADD',
    component: TopicCreateEditPage,
  },
  {
    path: '/topic-course/view/:topic_id',
    exact: true,
    name: 'Thêm chủ đề',
    function: 'TR_TOPIC_COURSE_EDIT',
    component: TopicCreateEditPage,
  },
  {
    path: '/topic-course/edit/:topic_id',
    exact: true,
    name: 'Sửa chủ đề',
    function: 'TR_TOPIC_COURSE_EDIT',
    component: TopicCreateEditPage,
  },
  {
    path: '/topic-course/copy/:topic_id',
    exact: true,
    name: 'Copy chủ đề',
    function: 'TR_TOPIC_COURSE_COPY',
    component: TopicCreateEditPage,
  },
  // #endregion

  //Test
  {
    path: '/training-test',
    exact: true,
    name: 'Danh sách bài kiểm tra',
    function: 'TR_TRAININGTEST_VIEW',
    component: TrainingTest,
  },
  {
    path: '/training-test/add',
    exact: true,
    name: 'Thêm mới',
    function: 'TR_TRAININGTEST_ADD',
    component: TrainingTestAdd,
  },
  {
    path: '/training-test/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'TR_TRAININGTEST_VIEW',
    component: TrainingTestDetail,
  },
  {
    path: '/training-test/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'TR_TRAININGTEST_EDIT',
    component: TrainingTestEdit,
  },

  {
    path: '/training-test/copy/:id',
    exact: true,
    name: 'Sao chép',
    function: 'TR_TRAININGTEST_ADD',
    component: TrainingTestCopy,
  },
  //Question
  {
    path: '/question',
    exact: true,
    name: 'Danh sách câu hỏi',
    function: 'TR_QUESTION_VIEW',
    component: Question,
  },
  {
    path: '/question/add',
    exact: true,
    name: 'Thêm mới',
    function: 'TR_QUESTION_ADD',
    component: QuestionAdd,
  },
  {
    path: '/question/add/:topicId',
    exact: true,
    name: 'Thêm mới',
    function: 'TR_QUESTION_ADD',
    component: QuestionAdd,
  },
  {
    path: '/question/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'TR_QUESTION_VIEW',
    component: QuestionDetail,
  },
  {
    path: '/question/edit/:id',
    exact: true,
    name: 'Chỉnh sửa',
    function: 'TR_QUESTION_EDIT',
    component: QuestionEdit,
  },
  //.end #Question
  //Test of user
  {
    path: '/training-test-user',
    exact: true,
    name: 'Danh sách bài kiểm tra',
    function: 'TR_TRAININGTESTUSER_VIEW',
    component: TrainingTestUser,
  },
  {
    path: '/training-test-user/doing/:id',
    exact: true,
    name: 'Làm bài kiểm tra',
    function: 'TR_TRAININGTESTUSER_DOING',
    component: TrainingTestUserDoing,
  },
  {
    path: '/training-test-user/edit/:id',
    exact: true,
    name: 'Tiếp tục làm bài kiểm tra',
    function: 'TR_TRAININGTESTUSER_DOING',
    component: TrainingTestUserEdit,
  },
  {
    path: '/training-test-user/detail/:id',
    exact: true,
    name: 'Kết quả bài kiểm tra',
    function: 'TR_TRAININGTESTUSER_DOING',
    component: TrainingTestResultDetail,
  },
  //.end #Testofuser

  {
    path: '/training-test/copy/:id',
    exact: true,
    name: 'Sao chép',
    function: 'TR_TRAININGTEST_ADD',
    component: TrainingTestCopy,
  },
  //#endregion
  // #region group course
  {
    path: '/course',
    exact: true,
    name: 'Quản lý khoá học',
    function: 'TR_COURSE_VIEW',
    component: CoursePage,
  },
  {
    path: '/course/add',
    exact: true,
    name: 'Thêm khoá học',
    function: 'TR_COURSE_ADD',
    component: CourseCreateEditPage,
  },
  {
    path: '/course/edit/:course_id',
    exact: true,
    name: 'Sửa khoá học',
    function: 'TR_COURSE_EDIT',
    component: CourseCreateEditPage,
  },
  {
    path: '/course/copy/:course_id',
    exact: true,
    name: 'Copy khoá học',
    function: 'TR_COURSE_COPY',
    component: CourseCreateEditPage,
  },
  {
    path: '/course/view/:course_id',
    exact: true,
    name: 'Xem khoá học',
    function: 'TR_COURSE_VIEW',
    component: CourseCreateEditPage,
  },

  // #region traning-tests
  {
    path: '/training-test-user',
    exact: true,
    name: 'Danh sách bài kiểm tra',
    function: 'TR_TRAININGTESTUSER_VIEW',
    component: TrainingTestUser,
  },
  {
    path: '/training-test-user/doing/:id',
    exact: true,
    name: 'Làm bài kiểm tra',
    function: 'TR_TRAININGTESTUSER_DOING',
    component: TrainingTestUserDoing,
  },
  {
    path: '/training-test-user/edit/:id',
    exact: true,
    name: 'Tiếp tục làm bài kiểm tra',
    function: 'TR_TRAININGTESTUSER_DOING',
    component: TrainingTestUserEdit,
  },
  {
    path: '/training-test-user/detail/:id',
    exact: true,
    name: 'Kết quả bài kiểm tra',
    function: 'TR_TRAININGTESTUSER_DOING',
    component: TrainingTestResultDetail,
  },
  // #endregion
  {
    path: '/training-test/copy/:id',
    exact: true,
    name: 'Sao chép',
    function: 'TR_TRAININGTEST_ADD',
    component: TrainingTestCopy,
  },

  // #region traning test results
  {
    path: '/training-test-result',
    exact: true,
    name: 'Danh sách kết quả bài kiểm tra',
    function: 'TR_TRAININGTEST_RESULT_VIEW',
    component: TrainingTestResult,
  },
  {
    path: '/training-test-result/review/:id',
    exact: true,
    name: 'Nhận xét đánh giá',
    function: 'TR_TRAININGTEST_RESULT_REVIEW',
    component: TrainingTestResultReview,
  },
  {
    path: '/training-test-result/detail/:id',
    exact: true,
    name: 'Chi tiết kết quả bài kiểm tra',
    function: 'TR_TRAININGTEST_RESULT_VIEW',
    component: TrainingTestResultDetail,
  },
  // #endregion

  // GoldenBoard
  {
    path: '/golden-board',
    exact: true,
    name: 'Danh sách bảng vàng',
    function: 'TR_GOLDBOARD_VIEW',
    component: GoldenBoard,
  },
  {
    path: '/golden-board/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'TR_GOLDBOARD_VIEW',
    component: GoldenBoardDetail,
  },

  //.end#GoldenBoard

  // PostTopic
  {
    path: '/post-topic',
    exact: true,
    name: 'Danh sách bài đăng diễn đàn',
    function: 'CMS_POSTTOPIC_VIEW',
    component: PostTopic,
  },
  {
    path: '/post-topic/detail/:id',
    exact: true,
    name: 'Chi tiết',
    function: 'CMS_POSTTOPIC_VIEW',
    component: PostTopicDetail,
  },
  {
    path: '/post-topic/review/:id',
    exact: true,
    name: 'Duyệt bài đăng',
    function: 'CMS_POSTTOPIC_VIEW',
    component: PostTopicReview,
  },

  //.end#PostTopic

  // #region traning test results
  {
    path: '/training-test-result',
    exact: true,
    name: 'Danh sách kết quả bài kiểm tra',
    function: 'TR_TRAININGTEST_RESULT_VIEW',
    component: TrainingTestResult,
  },
  {
    path: '/training-test-result/review/:id',
    exact: true,
    name: 'Nhận xét đánh giá',
    function: 'TR_TRAININGTEST_RESULT_REVIEW',
    component: TrainingTestResultReview,
  },
  {
    path: '/training-test-result/detail/:id',
    exact: true,
    name: 'Chi tiết kết quả bài kiểm tra',
    function: 'TR_TRAININGTEST_RESULT_VIEW',
    component: TrainingTestResultDetail,
  },
  // #endregion
  // #region topiccourse
  {
    path: '/forum-topic',
    exact: true,
    name: 'Quản lý chủ đề diễn dàn',
    function: 'CMS_FORUM_TOPIC_VIEW',
    component: ForumTopicPage,
  },
  {
    path: '/forum-topic/add',
    exact: true,
    name: 'Thêm chủ đề diễn đàn',
    function: 'CMS_FORUM_TOPIC_ADD',
    component: ForumTopicAddPage,
  },
  {
    path: '/forum-topic/view/:forum_topic_id',
    exact: true,
    name: 'Xem chủ đề',
    function: 'CMS_FORUM_TOPIC_VIEW',
    component: ForumTopicAddPage,
  },
  {
    path: '/forum-topic/edit/:forum_topic_id',
    exact: true,
    name: 'Sửa chủ đề',
    function: 'CMS_FORUM_TOPIC_EDIT',
    component: ForumTopicAddPage,
  },
  // #endregion
  // Price
  {
    path: "/price",
    exact: true,
    name: "Danh sách giá khoá học",
    function: "SL_PRICE_VIEW",
    component: Price,
  },
  {
    path: "/price/add",
    exact: true,
    name: "Làm giá bài giảng",
    function: "SL_PRICE_ADD",
    component: PriceAdd,
  },
  {
    path: "/price/detail/:id",
    exact: true,
    name: "Chi tiết giá bài giảng",
    function: "SL_PRICE_VIEW",
    component: PriceDetail,
  },
  {
    path: "/price/edit/:id",
    exact: true,
    name: "Chỉnh sửa giá",
    function: "SL_PRICE_EDIT",
    component: PriceEdit,
  },
  // Price lesson
  {
    path: "/price-lesson",
    exact: true,
    name: "Danh sách giá bài giảng",
    function: "SL_PRICE_LESSON_VIEW",
    component: PriceLesson,
  },
  {
    path: "/price-lesson/add",
    exact: true,
    name: "Làm giá bài giảng",
    function: "SL_PRICE_LESSON_ADD",
    component: PriceLessonAdd,
  },
  {
    path: "/price-lesson/detail/:id",
    exact: true,
    name: "Chi tiết giá bài giảng",
    function: "SL_PRICE_LESSON_VIEW",
    component: PriceLessonDetail,
  },
  {
    path: "/price-lesson/edit/:id",
    exact: true,
    name: "Chỉnh sửa giá bài giảng",
    function: "SL_PRICE_LESSON_EDIT",
    component: PriceLessonEdit,
  },
  // PostComment
  {
    path: '/post-comment',
    exact: true,
    name: 'Danh sách bình luận ',
    function: 'CMS_POSTTOPIC_VIEW',
    component: PostComment,
  },
  {
    path: '/post-comment/list/:id',
    exact: true,
    name: 'Chi tiết danh sách bình luận',
    function: 'CMS_POSTTOPIC_VIEW',
    component: PostCommentList,
  },
  {
    path: '/post-comment/reply/:id',
    exact: true,
    name: 'Chi tiết phản hồi bình luận',
    function: 'CMS_POSTTOPIC_VIEW',
    component: PostCommentReply,
  },  
    // ReceiptPaymentReviewLevel
    {
      path: "/receipt-payment-review-level",
      exact: true,
      name: "Mức duyệt thu chi",
      function: "SL_RECEIPTPAYMENT_REVIEWLEVEL_VIEW",
      component: ReceiptPaymentRL,
  },
  {
      path: "/receipt-payment-review-level/edit/:id",
      exact: true,
      name: "Chỉnh sửa",
      function: "SL_RECEIPTPAYMENT_REVIEWLEVEL_EDIT",
      component: ReceiptPaymentRLEdit,
  },
  {
      path: "/receipt-payment-review-level/detail/:id",
      exact: true,
      name: "Chi tiết",
      function: "SL_RECEIPTPAYMENT_REVIEWLEVEL_VIEW",
      component: ReceiptPaymentRLDetail,
  },
  {
      path: "/receipt-payment-review-level/add",
      exact: true,
      name: "Thêm mới",
      function: "SL_RECEIPTPAYMENT_REVIEWLEVEL_ADD",
      component: ReceiptPaymentRLAdd,
  },
  //.end#ReceiptPaymentReviewLevel
  // BusinessCustomer
  {
    path: "/business-customer",
    exact: true,
    name: "đối tác",
    function: "CRM_BUSINESS_CUSTOMER_VIEW",
    component: BusinessCustomer,
  },
  {
    path: "/business-customer/edit/:id",
    exact: true,
    name: "sửa đối tác",
    function: "CRM_BUSINESS_CUSTOMER_EDIT",
    component: BusinessCustomerEdit,
  },
  {
    path: "/business-customer/detail/:id",
    exact: true,
    name: "Chi tiết đối tác",
    function: "CRM_BUSINESS_CUSTOMER_VIEW",
    component: BusinessCustomerDetail,
  },
  {
    path: "/business-customer/add",
    exact: true,
    name: "Thêm mới đối tác",
    function: "CRM_BUSINESS_CUSTOMER_ADD",
    component: BusinessCustomerAdd,
},
//.end#BusinessCustomer

  //FAQs
  {
      path: '/FAQs',
      exact: true,
      name: 'Quản lý FAQs',
      function: 'CMS_FAQS_VIEW',
      component: FAQs,
  },
  {
      path: "/FAQs/add",
      exact: true,
      name: "Thêm mới",
      function: "CMS_FAQS_ADD",
      component: FAQsAdd,
  },
  {
      path: '/FAQs/detail/:id',
      exact: true,
      name: 'Chi tiết',
      function: 'CMS_FAQS_VIEW',
      component: FAQsDetail,
  },
  {
      path: '/FAQs/edit/:id',
      exact: true,
      name: 'Chỉnh sửa',
      function: 'CMS_FAQS_EDIT',
      component: FAQsEdit,
  },
  //.end#FAQs

  // Admin Website: Topic
  {
      path: "/topic",
      exact: true,
      name: "Danh sách chủ đề",
      function: "CMS_TOPIC_VIEW",
      component: Topic,
  },
  {
      path: "/topic/add",
      exact: true,
      name: "Thêm mới",
      function: "CMS_TOPIC_ADD",
      component: TopicAdd,
  },
  {
      path: "/topic/detail/:id",
      exact: true,
      name: "Chi tiết",
      function: "CMS_TOPIC_VIEW",
      component: TopicDetail,
  },
  {
      path: "/topic/edit/:id",
      exact: true,
      name: "Chỉnh sửa",
      function: "CMS_TOPIC_EDIT",
      component: TopicEdit,
  },
  //.end#Topic
  // Support
  {
      path: '/support',
      exact: true,
      name: 'Danh sách hỗ trợ',
      function: 'CMS_SUPPORT_VIEW',
      component: Support,
  },
  {
      path: '/support/detail/:id',
      exact: true,
      name: 'Chi tiết',
      function: 'CMS_SUPPORT_VIEW',
      component: SupportDetail,
  },
  {
      path: '/support/edit/:id',
      exact: true,
      name: 'Chỉnh sửa',
      function: 'CMS_SUPPORT_EDIT',
      component: SupportEdit,
  },
  //.end#Support
  //.end#PostComment
  //.end#Prices
  // #endregion
];
//.End

export default routes;
