import types from 'components/ForumTopic/actions/types';
import { defaultPaging } from 'utils/helpers';

const INITIAL_STATE = {
  listForumTopic: defaultPaging,
  getListForumTopicLoading: false,

  forumTopicData: undefined,
  getForumTopicLoading: false,

  createForumTopicLoading: false,
  updateForumTopicLoading: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_LIST_FORUM_TOPIC_REQUEST:
      return {
        ...state,
        getListForumTopicLoading: true,
      };
    case types.GET_LIST_FORUM_TOPIC_SUCCESS:
      return {
        ...state,
        listForumTopic: action.payload,
        getListForumTopicLoading: false,
      };
    case types.GET_LIST_FORUM_TOPIC_FAILURE:
      return {
        ...state,
        getListForumTopicLoading: false,
      };

    case types.GET_FORUM_TOPIC_REQUEST:
      return {
        ...state,
        forumTopicData: undefined,
        forumTopicDataLoading: true,
      };
    case types.GET_FORUM_TOPIC_SUCCESS:
      return {
        ...state,
        forumTopicData: action.payload,
        forumTopicDataLoading: false,
      };
    case types.GET_FORUM_TOPIC_FAILURE:
      return {
        ...state,
        forumTopicDataLoading: false,
      };

    case types.CREATE_FORUM_TOPIC_REQUEST:
      return {
        ...state,
        createForumTopicLoading: true,
      };
    case types.CREATE_FORUM_TOPIC_SUCCESS:
    case types.CREATE_FORUM_TOPIC_FAILURE:
      return {
        ...state,
        createForumTopicLoading: false,
      };

    case types.UPDATE_FORUM_TOPIC_REQUEST:
      return {
        ...state,
        updateForumTopicLoading: true,
      };
    case types.UPDATE_FORUM_TOPIC_SUCCESS:
    case types.UPDATE_FORUM_TOPIC_FAILURE:
      return {
        ...state,
        updateForumTopicLoading: false,
      };

    default:
      return state;
  }
}
