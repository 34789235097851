import { combineReducers } from 'redux';
import * as common from './common';
import * as product from './product';
import * as model from './model';
import * as users from './users';
import training from 'components/Training/reducers';
import forumTopic from 'components/ForumTopic/reducers';

const noPersist = {
  training,
  forumTopic
};

const reducers = combineReducers({
  ...common,
  ...users,
  ...product,
  ...model,
  ...noPersist,
});
export { reducers, noPersist };
