export default {
  // #region topic
  GET_LIST_FORUM_TOPIC_REQUEST: 'GET_LIST_FORUM_TOPIC_REQUEST',
  GET_LIST_FORUM_TOPIC_SUCCESS: 'GET_LIST_FORUM_TOPIC_SUCCESS',
  GET_LIST_FORUM_TOPIC_FAILURE: 'GET_LIST_FORUM_TOPIC_FAILURE',

  GET_FORUM_TOPIC_REQUEST: 'GET_FORUM_TOPIC_REQUEST',
  GET_FORUM_TOPIC_SUCCESS: 'GET_FORUM_TOPIC_SUCCESS',
  GET_FORUM_TOPIC_FAILURE: 'GET_FORUM_TOPIC_FAILURE',

  CREATE_FORUM_TOPIC_REQUEST: 'CREATE_FORUM_TOPIC_REQUEST',
  CREATE_FORUM_TOPIC_SUCCESS: 'CREATE_FORUM_TOPIC_SUCCESS',
  CREATE_FORUM_TOPIC_FAILURE: 'CREATE_FORUM_TOPIC_FAILURE',

  UPDATE_FORUM_TOPIC_REQUEST: 'UPDATE_FORUM_TOPIC_REQUEST',
  UPDATE_FORUM_TOPIC_SUCCESS: 'UPDATE_FORUM_TOPIC_SUCCESS',
  UPDATE_FORUM_TOPIC_FAILURE: 'UPDATE_FORUM_TOPIC_FAILURE',

  DELETE_FORUM_TOPIC_REQUEST: 'DELETE_FORUM_TOPIC_REQUEST',
  DELETE_FORUM_TOPIC_SUCCESS: 'DELETE_FORUM_TOPIC_SUCCESS',
  DELETE_FORUM_TOPIC_FAILURE: 'DELETE_FORUM_TOPIC_FAILURE',
  // #endregion
};
