// @var String
export const USER_ADD = 'USER_ADD';
// @var String
export const USER_DEL = 'USER_DEL';
// @var String
export const USER_EDIT = 'USER_EDIT';
// @var String
export const USER_SET = 'USER_SET';

// @var String
export const USER_AUTH_SET = 'USER_AUTH_SET';
// @var String
// export const USER_AUTH_CLR = 'USER_AUTH_CLR';
