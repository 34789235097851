/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import { Label } from 'reactstrap';
import moment from 'moment';
import { parse, isDate } from 'date-fns';
/**
 * Is string?
 * @return {bool|mixed}
 */
export function isString(data, rtnData) {
  let rs = '[object String]' === Object.prototype.toString.call(data);
  return undefined !== rtnData ? (rs ? data : rtnData) : rs;
}

/**
 * Is plain object?
 * @return {bool|mixed}
 */
export function isPlainObject(data, rtnData) {
  let rs = '[object Object]' === Object.prototype.toString.call(data);
  return undefined !== rtnData ? (rs ? data : rtnData) : rs;
}

/**
 * Is boolean?
 * @return {bool|mixed}
 */
export function isBoolean(data, rtnData) {
  let rs = '[object Boolean]' === Object.prototype.toString.call(data);
  return undefined !== rtnData ? (rs ? data : rtnData) : rs;
}
export function isBool(data, rtnData) {
  return isBoolean(data, rtnData);
}

/**
 * Is void (null or undefined)
 * @return {bool}
 */
export function isVoid(data, rtnData) {
  let rs = data === null || data === undefined;
  return undefined !== rtnData ? (rs ? data : rtnData) : rs;
}

/**
 * Gen simple unique ID string
 * @return {String}
 */
export function uniqueID() {
  return (new Date().getTime() + Math.random()).toString();
}

/**
 *
 * @return {String}
 */
/* export function encryptPassword(password) {
    const hash = crypto.createHmac('sha256', encryptPassword.salt)
      .update(password)
      .digest('hex')
    ;
    return hash;
}
encryptPassword.salt = '123-456-789';
*/

/**
 * https://stackoverflow.com/questions/6274339/how-can-i-shuffle-an-array
 * Shuffles array in place.
 * @param {Array} a items An array containing the items.
 * @param {Object} b An object containing indexes mapping.
 */
export function shuffle(a, b) {
  var j, x, i;
  b = typeof b === 'object' ? b : {};
  for (i = a.length - 1; i >= 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    b[i] = j;
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

/**
 * Config table object?
 * @return {Object}
 */
export function configTableOptions(count = 0, page, query, itemsPerPage) {
  return {
    fixedHeader: true,
    filterType: 'dropdown',
    selectableRows: 'single',
    responsive: 'scroll',
    count: count,
    page: 0,
    rowsPerPage: itemsPerPage || query?.itemsPerPage || 25,
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    filter: false,
    pagination: false,
    textLabels: {
      body: {
        noMatch: 'Không tìm thấy dữ liệu.',
        toolTip: 'Sắp xếp',
      },
      pagination: {
        next: 'Trang tiếp theo',
        previous: 'Trang trước đó',
        rowsPerPage: 'Số dòng trên trang:',
        displayRows: 'của',
      },
    },
    fixedSelectColumn: true,
    tableBodyHeight: '400px',
    tableBodyMaxHeight: "400px",
  };
}

/**
 * Config table object?
 * @return {Object}
 */
export function configTableOptionsRender(count, page, query, render = {}) {
  return {
    fixedHeader: true,
    filterType: 'dropdown',
    selectableRows: 'single',
    responsive: 'scroll',
    count: count,
    page: 0,
    rowsPerPage: query.itemsPerPage,
    rowsPerPageOptions: [25, 50, 75, 100],
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    filter: false,
    pagination: false,
    textLabels: {
      body: {
        noMatch: 'Không tìm thấy dữ liệu.',
        toolTip: 'Sắp xếp',
      },
      pagination: {
        next: 'Trang tiếp theo',
        previous: 'Trang trước đó',
        rowsPerPage: 'Số dòng trên trang:',
        displayRows: 'của',
      },
    },
    ...render,
  };
}
/**
 * Config table STT object?
 * @return {Object}
 */
export function configIDRowTable(fied = '', url = '', query = {}, customSTT = null) {
  return {
    name: fied,
    label: 'STT',
    options: {
      filter: false,
      sort: false,
      customHeadRender: (columnMeta) => {
        return (
          <th
            key={`head-th-${columnMeta.label}`}
            className='MuiTableCell-root MuiTableCell-head'
            style={{ width: '20px' }}>
            <div className='text-center'>{columnMeta.label}</div>
          </th>
        );
      },
      customBodyRender: (value, tableMeta) => {
        let stt =
          query.page > 1 ? (query.page - 1) * query.itemsPerPage + (tableMeta.rowIndex + 1) : tableMeta.rowIndex + 1;
        stt = customSTT ? tableMeta.rowData[customSTT] : stt;
        return (
          <div
            // className={!customSTT ? "text-center" : ""}
            className='text-center'
            style={{
              width: '40px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}>
            {url ? <Link to={`${url}${value}`}>{stt}</Link> : <Label>{stt}</Label>}
          </div>
        );
      },
    },
  };
}

// @var {Intl.NumberFormat}
const numberFormatIntl = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' });
/**
 * Number format
 * @param {Number|String} val
 * @return {String}
 */
export function numberFormat(val) {
  return numberFormatIntl.format(val);
}

export function numberWithCommas(number, currency = false) {
  let suffix = '';
  if (currency) suffix = ' ₫';
  var nf = new Intl.NumberFormat('de-DE');
  return nf.format(number) + suffix; // "1,234,567,890"
}

export function tryParseJSON(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return {};
  }
}

export function convertStringToNumber(string, char = '.') {
  const value =
    char == '.' ? `${string + ''}`.replace(/\./g, '').replace(/,/g, '.') : `${string + ''}`.replace(/,/g, '');
  return !value ? 0 : parseFloat(value);
}

export function changeToSlug(str) {
  if (!str) return '';
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  //Đổi ký tự có dấu thành không dấu
  str = str.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
  str = str.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
  str = str.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
  str = str.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
  str = str.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
  str = str.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
  str = str.replace(/đ/gi, 'd');

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}

export function isOutputCoil(output_type = '') {
  output_type = `${output_type}`.trim();
  output_type = changeToSlug(output_type);
  if (output_type.indexOf('cuon') >= 0 || output_type.indexOf('ban-cuon') >= 0) return true;
  return false;
}

export function upperFirstChar(string, suffix = 'đồng') {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) + ` ${suffix}` : '';
}

export function isImage(string = '') {
  if (!string) return false;
  if (string.startsWith('data:image')) return true;
  if (string.match(/\.(jpeg|jpg|gif|png)$/) != null) return true;
  return false;
}
export function splitString(str = '', n, useWordBoundary = true) {
  if (!str) return '';
  if (str.length <= n) {
    return str;
  }
  const subString = str.substr(0, n - 1);
  return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString) + '...';
}
export function splitStringNew(str, n, useWordBoundary = true) {
  if (str.length <= n) {
    return str;
  } else {
    const subString = str.substr(0, n - 1);
    return subString + '...';
  }
}
export function getNestedParent(arr, path) {
  let result;
  //path = '/' + (path || "").replace(/^\/([^/]*).*$/, "$1");
  arr.some(({ id, url, children = [] }) => {
    if (path.includes(url)) return (result = id);
    var temp = getNestedParent(children, path);
    if (temp) return (result = id + '.' + temp);
  });
  return result;
}

export function getNestedParentMenuToArray(arr, path, isShift = true) {
  const list = getNestedParent(arr, path);
  if (!list) return [];
  let arrMenu = list.split('.');
  if (arrMenu.length <= 1) return arrMenu;
  return isShift ? arrMenu.slice(1) : arrMenu;
}

export function toObject(arr = [], key, addon = {}) {
  if (!key || !arr.length) return {};
  return (arr || []).reduce((_obj, o, i) => {
    _obj[o[key]] = Object.assign(o, addon);
    return _obj;
  }, {});
}

export function toArray(object) {
  const arr = Object.entries(object);
  return (arr || []).map((v) => v[1]);
}

export function convertUnitDensity(density, params) {
  let isComplete = false,
    value = 0;
  let { input_unit_id, output_unit_id, quantity } = params;
  if (!output_unit_id || output_unit_id == input_unit_id) return quantity;
  while (!isComplete) {
    const densityList = (density || []).filter(
      (v) => v.sub_unit_id == input_unit_id || v.main_unit_id == input_unit_id,
    );
    if (densityList.length) {
      (densityList || []).forEach((v) => {
        const { density_value, sub_unit_id, main_unit_id } = v;
        if (
          (sub_unit_id == input_unit_id || main_unit_id == input_unit_id) &&
          (sub_unit_id == output_unit_id || main_unit_id == output_unit_id)
        ) {
          // TH tim ra duoc dau tien
          if (sub_unit_id == input_unit_id) {
            value = quantity * density_value;
          } else value = quantity / (density_value || 1);
          isComplete = true;
        }
      });
    }
    if (!densityList.length) {
      isComplete = true;
    } else {
      const { sub_unit_id, main_unit_id, density_value } = densityList[0];
      quantity = sub_unit_id == input_unit_id ? quantity * density_value : quantity / (density_value || 1);
      input_unit_id = sub_unit_id == input_unit_id ? main_unit_id : sub_unit_id;
    }
  }
  return value;
}

export const yupDateTransform = (value, originalValue) => {
  if (moment(originalValue) instanceof moment) {
    originalValue = moment(originalValue, 'DD/MM/YYYY').format('DD/MM/YYYY');
  }
  return isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
};

export function getExtension(filename) {
  var parts = filename.split('.');
  return parts[parts.length - 1];
}

export function isVideo(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'm4v':
    case 'avi':
    case 'mpg':
    case 'mp4':
    case 'mov':
      return true;
  }
  return false;
}

export function isDocument(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'pdf':
    case 'xls':
    case 'xlsx':
    case 'png':
    case 'jpg':
    case 'gift':
    case 'jpeg':
    case 'doc':
    case 'docx':
      return true;
  }
  return false;
}

export function formatPrice(price) {
  if (!price) return 0;
  return new Intl.NumberFormat().format(price || 0);
}
const pad = (num, size) => {
  var s = '000000000' + num;
  return s.substr(s.length - size);
};

export const buildSku = (stocks_id = '', count = 0, rightNum = 5, date = moment().format('YYMMDD')) => {
  count = count + 1;
  count = pad(count, rightNum);
  return `${pad(stocks_id, 4)}${date}${count}`;
};

export const formatPoint = (x) => {
  if (!x) return 0;
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ' ');
};

export const changeAlias = (val) => {
  var str = val + '';
  str = str ? str.trim() : '';
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ');
  str = str.replace(/ + /g, '-');
  str = str.replace(/[ ]/g, '-');
  return str;
};
