const STATUS_TOASTR = {
  success: 'success',
  error: 'error',
  warning: 'warning',
};

const defaultLabel = {
  label: '--Chọn--',
};

const defaultPaging = {
  items: [],
  totalItems: 0,
  page: 0,
  totalPages: 0,
  itemsPerPage: 0,
};

const defaultPayload = {
  page: 0,
  itemsPerPage: 2147483647,
};

const deburr = (s) => {
  let result = s ?? '';
  result = result.toLowerCase();
  result = result.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  result = result.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  result = result.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  result = result.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  result = result.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  result = result.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  result = result.replace(/đ/g, 'd');
  result = result.replace(
    // eslint-disable-next-line no-useless-escape
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ' ',
  );
  result = result.replace(/ + /g, ' ');
  result = result.trim();
  return result;
};

const getFirstLetter = (str) => {
  const words = `${str}`.split(' ');
  const firstWord = words[0];
  return firstWord[0];
};

// eslint-disable-next-line no-restricted-globals
const isNumberic = (num) => !isNaN(num);

const formatVNCurrency = (number) => `${number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} ₫`;

const toastrShow = (message, status) => window._$g.toastr.show(message, STATUS_TOASTR[status]);

const get_name_extension =  ( url ) => {
  return url.split(/[#?]/)[0].split('.').pop().trim();
}

const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }

  return true;
}

const isObject = (object) => {
  return object != null && typeof object === 'object';
}

const cloneData = (item) => {
  if (!item) { return item; } // null, undefined values check

  var types = [ Number, String, Boolean ], 
      result;

  // normalizing primitives if someone did new String('aaa'), or new Number('444');
  types.forEach(function(type) {
      if (item instanceof type) {
          result = type( item );
      }
  });

  if (typeof result == "undefined") {
      if (Object.prototype.toString.call( item ) === "[object Array]") {
          result = [];
          item.forEach(function(child, index, array) { 
              result[index] = cloneData( child );
          });
      } else if (typeof item == "object") {
          // testing that this is DOM
          if (item.nodeType && typeof item.cloneNode == "function") {
              result = item.cloneNode( true );    
          } else if (!item.prototype) { // check that this is a literal
              if (item instanceof Date) {
                  result = new Date(item);
              } else {
                  // it is an object literal
                  result = {};
                  for (var i in item) {
                      result[i] = cloneData( item[i] );
                  }
              }
          } else {
              // depending what you would like here,
              // just keep the reference, or create new object
              if (false && item.constructor) {
                  // would not advice to do that, reason? Read below
                  result = new item.constructor();
              } else {
                  result = item;
              }
          }
      } else {
          result = item;
      }
  }

  return result;
}

const changeToSlug = (str) =>{
  try {
      if (!str) return "";
      if (str) {
          str = str.replace(/^\s+|\s+$/g, ""); // trim
          str = str.toLowerCase();

          //Đổi ký tự có dấu thành không dấu
          str = str.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, "a");
          str = str.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, "e");
          str = str.replace(/i|í|ì|ỉ|ĩ|ị/gi, "i");
          str = str.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, "o");
          str = str.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, "u");
          str = str.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, "y");
          str = str.replace(/đ/gi, "d");

          str = str
              .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
              .replace(/\s+/g, "-") // collapse whitespace and replace by -
              .replace(/-+/g, "-"); // collapse dashes
      }
      return str;
  } catch (error) { }
}

const removeSysBol = (valueT, sys, valueDefault = '') => {
  let result = valueDefault;
  
  if(valueT) {
    const re = new RegExp(sys, "g");
    const valueToString = valueT.toString();
    result = valueToString.replace(re,'');
  }

  return result;
}

export {
  defaultPaging,
  defaultPayload,
  getFirstLetter,
  isNumberic,
  deburr,
  formatVNCurrency,
  toastrShow,
  defaultLabel,
  get_name_extension,
  deepEqual,
  cloneData,
  changeToSlug,
  removeSysBol,
};
