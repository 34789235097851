import types from 'components/Training/actions/types';
import { defaultPaging } from 'utils/helpers';

const INITIAL_STATE = {
  listCompany: defaultPaging,
  isLoadingCompany: false,
  getListCompanyLoading: false,

  listUserByCompany: defaultPaging,
  getListUserByCompanyLoading: false,

  listCourse: defaultPaging,
  getListCourseLoading: false,

  listTopic: defaultPaging,
  getListTopicLoading: false,

  listLesson: defaultPaging,
  getListLessonLoading: false,

  lessonData: undefined,
  getLessonLoading: false,

  listLecturer: defaultPaging,
  getLecturerLoading: false,

  topicData: undefined,
  getTopicLoading: false,

  createCourseLoading: false,
  updateCourseLoading: false,

  createTopicLoading: false,
  updateTopicLoading: false,

  createLessonLoading: false,
  updateLessonLoading: false,

  courseData: undefined,
  courseDataLoading: false,

  percentCourseDetail: 0,

  courseStatusListAll: undefined,
  courseStatusListAllLoading: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_LIST_COMPANY_REQUEST:
      return {
        ...state,
        getListCompanyLoading: true,
      };
    case types.GET_LIST_COMPANY_SUCCESS:
      return {
        ...state,
        listCompany: action.payload,
        getListCompanyLoading: false,
        isLoadingCompany: true,
      };
    case types.GET_LIST_COMPANY_FAILURE:
      return {
        ...state,
        getListCompanyLoading: false,
      };

    case types.GET_COURSE_STATUS_REQUEST:
      return {
        ...state,
        courseStatusListAllLoading: true,
      };
    case types.GET_COURSE_STATUS_SUCCESS:
      return {
        ...state,
        courseStatusListAll: action.payload,
        courseStatusListAllLoading: false,
      };

    case types.GET_LIST_USER_BY_COMPANY_REQUEST:
      return {
        ...state,
        getListUserByCompanyLoading: true,
      };
    case types.GET_LIST_USER_BY_COMPANY_SUCCESS:
      return {
        ...state,
        listUserByCompany: action.payload,
        getListUserByCompanyLoading: false,
      };
    case types.GET_LIST_USER_BY_COMPANY_FAILURE:
      return {
        ...state,
        getListUserByCompanyLoading: false,
      };

    case types.GET_LIST_COURSE_REQUEST:
      return {
        ...state,
        getListCourseLoading: true,
      };
    case types.GET_LIST_COURSE_SUCCESS:
      return {
        ...state,
        listCourse: action.payload,
        getListCourseLoading: false,
      };
    case types.GET_LIST_COURSE_FAILURE:
      return {
        ...state,
        getListCourseLoading: false,
      };

    case types.GET_COURSE_REQUEST:
      return {
        ...state,
        courseData: undefined,
        courseDataLoading: true,
      };
    case types.GET_COURSE_SUCCESS:
      return {
        ...state,
        courseData: action.payload,
        courseDataLoading: false,
      };
    case types.GET_COURSE_FAILURE:
      return {
        ...state,
        courseDataLoading: false,
      };

    case types.GET_LIST_LECTURER_REQUEST:
      return {
        ...state,
        getLecturerLoading: true,
      };
    case types.GET_LIST_LECTURER_SUCCESS:
      return {
        ...state,
        listLecturer: action.payload,
        getLecturerLoading: false,
      };
    case types.GET_LIST_LECTURER_FAILURE:
      return {
        ...state,
        getLecturerLoading: false,
      };

    case types.GET_LIST_TOPIC_REQUEST:
      return {
        ...state,
        getListTopicLoading: true,
      };
    case types.GET_LIST_TOPIC_SUCCESS:
      return {
        ...state,
        listTopic: action.payload,
        getListTopicLoading: false,
      };
    case types.GET_LIST_TOPIC_FAILURE:
      return {
        ...state,
        getListTopicLoading: false,
      };

    case types.GET_TOPIC_REQUEST:
      return {
        ...state,
        topicData: undefined,
        topicDataLoading: true,
      };
    case types.GET_TOPIC_SUCCESS:
      return {
        ...state,
        topicData: action.payload,
        topicDataLoading: false,
      };
    case types.GET_TOPIC_FAILURE:
      return {
        ...state,
        topicDataLoading: false,
      };

    case types.CREATE_COURSE_REQUEST:
      return {
        ...state,
        createCourseLoading: true,
      };
    case types.CREATE_COURSE_SUCCESS:
    case types.CREATE_COURSE_FAILURE:
      return {
        ...state,
        createCourseLoading: false,
      };

    case types.UPDATE_COURSE_REQUEST:
      return {
        ...state,
        updateCourseLoading: true,
      };
    case types.UPDATE_COURSE_SUCCESS:
    case types.UPDATE_COURSE_FAILURE:
      return {
        ...state,
        updateCourseLoading: false,
      };

    case types.CREATE_TOPIC_REQUEST:
      return {
        ...state,
        createTopicLoading: true,
      };
    case types.CREATE_TOPIC_SUCCESS:
    case types.CREATE_TOPIC_FAILURE:
      return {
        ...state,
        createTopicLoading: false,
      };

    case types.UPDATE_TOPIC_REQUEST:
      return {
        ...state,
        updateTopicLoading: true,
      };
    case types.UPDATE_TOPIC_SUCCESS:
    case types.UPDATE_TOPIC_FAILURE:
      return {
        ...state,
        updateTopicLoading: false,
      };

    case types.GET_LIST_LESSON_REQUEST:
      return {
        ...state,
        getListLessonLoading: true,
      };
    case types.GET_LIST_LESSON_SUCCESS:
      return {
        ...state,
        listLesson: action.payload,
        getListLessonLoading: false,
      };
    case types.GET_LIST_LESSON_FAILURE:
      return {
        ...state,
        getListLessonLoading: false,
      };

    case types.GET_LESSON_REQUEST:
      return {
        ...state,
        getLessonLoading: true,
      };
    case types.GET_LESSON_SUCCESS:
      return {
        ...state,
        lessonData: action.payload,
        getLessonLoading: false,
      };
    case types.GET_LESSON_FAILURE:
      return {
        ...state,
        getLessonLoading: false,
      };

    case types.CREATE_LESSON_REQUEST:
      return {
        ...state,
        createLessonLoading: true,
      };

    case types.CREATE_LESSON_SUCCESS:
    case types.CREATE_LESSON_FAILURE:
      return {
        ...state,
        createLessonLoading: false,
      };

    case types.UPDATE_LESSON_REQUEST:
      return {
        ...state,
        updateLessonLoading: true,
      };
    case types.UPDATE_LESSON_SUCCESS:
    case types.UPDATE_LESSON_FAILURE:
      return {
        ...state,
        updateLessonLoading: false,
      };
    case types.UPDATE_PERCENT_DETAIL:
      return {
        ...state,
        percentCourseDetail: action.payload,
      };
    default:
      return state;
  }
}
