import React, {Component} from 'react';
import {Spinner, Modal, ModalBody} from 'reactstrap';

/**
 * @class Loading
 */
export default class Loading extends Component {
    render() {
        const {useModal = false, msg = 'Đang tải lên ....'} = this.props;
        if (!useModal)
            return (
                <div className="animated fadeIn pt-1 text-center">
                    <Spinner type="grow" color="primary" />
                    <Spinner type="grow" color="secondary" />
                    <Spinner type="grow" color="success" />
                    <Spinner type="grow" color="danger" />
                    <Spinner type="grow" color="warning" />
                    <Spinner type="grow" color="info" />
                    <Spinner type="grow" color="light" />
                    <Spinner type="grow" color="dark" />
                </div>
            );
        if (useModal)
            return (
                <div>
                    <Modal centered isOpen={true} backdrop="static" style={{width: '200px'}}>
                        <ModalBody style={{width: '200px'}}>
                            <div className="text-center">
                                <span>
                                    <b>{msg}</b>
                                </span>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            );
    }
}

export function loading() {
    return <Loading />;
}
