import { PRODUCT_LIST } from "../actions/constants/product"

const initialState = {
    productList: [],
}

export const product = (state = initialState, { type, payload }) => {
    switch (type) {

        case PRODUCT_LIST:
            return { ...state, productList: payload }

        default:
            return state
    }
}
