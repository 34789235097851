import { MODELNAMELIST } from "actions/constants/model"

const initialState = {
    modelList: [],
}

export const model = (state = initialState, { type, payload }) => {
    switch (type) {

        case MODELNAMELIST: {
            return { ...state, modelList: payload }
        }


        default:
            return state
    }
}
