import Model from '../Model';
import UserEntity from '../UserEntity';

import {
    userAuthSet
} from '../../actions/user';


export default class UserModel extends Model {
    _stateKeyName = 'users';

    _entity = UserEntity;

    static API_USER_AUTH = 'auth/token';
    static API_USER_PROFILE = 'auth/get-profile';
    static API_USER_LOGOUT = 'auth/logout';
    static API_USER_LIST = 'user';
    static API_USER_OPTS = 'user/get-options';
    static API_USER_INIT = 'user/create';
    static API_USER_CREATE = 'user';
    static API_USER_UPDATE = 'user/:id'; // PUT
    static API_USER_READ = 'user/:id'; // GET
    static API_USER_DELETE = 'user/:id'; // DELETE
    static API_USER_CHANGE_PASSWORD = 'user/:id/change-password'; // PUT
    static API_CHANGE_PASSWORD_USER = 'user/:id/change-password-user'; // PUT
    static API_USER_FUNCTIONS = 'function/functions-by-user-group'; // GET
    static API_USER_LIST_DIVISON = 'user/business-user/divison';
    static API_CHANGE_IMAGE_USER = 'user/:id/change-image-user'; //

    primaryKey = 'user_id';

    static columnPrefix = '';

    static _jqxGridColumns = [
        { datafield: 'user_id' },
        {
            text: 'Tên đăng nhập',
            datafield: 'user_name',
            pinned: true,
            width: 120,
        },
        {
            text: 'Họ',
            datafield: 'first_name',
            pinned: true,
            width: 120,
        },
        {
            text: 'Tên',
            datafield: 'last_name',
            pinned: true,
            width: 120,
        },
        { datafield: 'department_id' },
        {
            text: 'Phòng ban',
            datafield: 'department',
            sortable: false,
            width: 120,
        },
        { datafield: 'position_id' },
        {
            text: 'Chức vụ',
            datafield: 'position_text',
            width: 80,
        },
        { datafield: 'gender' },
        {
            text: 'Giới tính',
            datafield: 'gender_text',
            width: 80,
        },
        {
            text: 'Email',
            datafield: 'email',
            width: 160,
            sortable: false,
        },
        {
            text: 'Ngày sinh',
            datafield: 'birthday',
            sortable: false,
            width: 100,
            cellsalign: 'center',
        },
        {
            text: 'Địa chỉ',
            datafield: 'address',
            width: 160,
            sortable: false,
        },
        {
            text: 'Điện thoại',
            datafield: 'phone_number',
            width: 120,
            sortable: false,
        },
        {
            text: 'Điện thoại 2',
            datafield: 'phone_number_1',
            width: 120,
            sortable: false,
        },
        {
            text: 'Giới thiệu sơ lược',
            datafield: 'about_me',
            sortable: false,
            filterable: false,
            minwidth: 160,
        }
    ];

    static defaultImgBase64 = UserEntity.defaultImgBase64;

    fillable = () => ({
        "user_name": "",
        "password": "",
        "password_confirm": "",
        "first_name": "",
        "last_name": "",
        "gender": "0",
        "birthday": "",
        "email": "",
        "phone_number": "",
        "phone_number_1": "",
        "address": "",
        "country_id": "1",
        "province_id": "",
        "district_id": "",
        "city_id": "",
        "ward_id": "",
        "picture": "",
        "default_picture_url": "",
        "department_id": "",
        "position_id": "",
        "about_me": "",
        "description": "",
        "user_groups": [],
        "entry_date": null,
        "identity_date": null,
        "identity_number": "",
        "identity_place": "",
        "identity_front_image": null,
        "identity_back_image": null,
        "is_active": 1,
        "quit_job_date": null,
        "is_time_keeping": 1
    });


    static jqxGridProps(opts) {
        let _self = new _static();
        opts = Object.assign({
            prefix: _static.columnPrefix,
            postBeforeProcessing: (data) => {
                (data.items || []).forEach((item) => {
                    if ('gender' in item) {
                        item.gender_text = window._$g._(item.gender ? 'Nam' : 'Nữ')
                    }
                });
            }
        }, opts);

        let props = Model.jqxGridProps(_static._jqxGridColumns, opts);
        Object.assign(props.source, {
            url: _static.apiClass.buildApiUri(_static.API_USER_LIST),
            id: _self.primaryKey
        });
        return props;
    }

    static buildAuthHeader() {
        let auth = _static.getUserAuthStatic();
        return {
            name: auth ? auth.tokenKey : null,
            value: auth ? `${auth.tokenType} ${auth.accessToken}` : null,
        };
    }

    getUserAuth() {
        let userAuth = this._store.getState()['userAuth'];
        return userAuth && (new UserEntity(userAuth));
    }

    static getUserAuthStatic() {
        let userAuth = _static._storeStatic.getState()['userAuth'];
        return userAuth && (new UserEntity(userAuth));
    }

    static getPrefsStatic(key) {
        let prefs = {};
        let userAuth = window._$g.userAuth || _static.getUserAuthStatic();
        if (userAuth) {
            prefs = userAuth._prefs || {};
        }
        return key ? prefs[key] : prefs;
    }

    static addPrefStatic(key, value) {
        let userAuth = window._$g.userAuth || _static.getUserAuthStatic();
        if (userAuth) {
            let prefs = userAuth._prefs || {};
            prefs[key] = value;
            userAuth._prefs = prefs;
            _static.storeUserAuthStatic(userAuth);
        }
    }

    static setPrefStatic(prefs) {
        let userAuth = window._$g.userAuth || _static.getUserAuthStatic();
        if (userAuth) {
            userAuth._prefs = prefs || {};
            _static.storeUserAuthStatic(userAuth);
        }
    }

    static storeUserAuthStatic(data) {
        let userAuth = _static.getUserAuthStatic();
        let authData = Object.assign(userAuth || {}, data);
        _static._storeStatic.dispatch(userAuthSet(authData));
        return data;
    }

    login(user_name, password, remember) {
        let platform = 'portal';
        return this._api.post(_static.API_USER_AUTH, {
            user_name: user_name, password, platform, remember
        })
            .then(loginData => _static.storeUserAuthStatic(loginData)
                && this._api.get(_static.API_USER_PROFILE)
                    .then(profileData => {
                        _static.storeUserAuthStatic(Object.assign(profileData, {
                            _prefs: {} // Self init user's preferences,...
                        }));
                        if (!profileData.isAdministrator) {
                            return this._api.get(_static.API_USER_FUNCTIONS)
                                .then(_functions => _static.storeUserAuthStatic({ _functions }));
                        }
                        return profileData;
                    })
            )
            ;
    }

    logout(data) {
        return new Promise((resolve) => {
            return this._api.post(_static.API_USER_LOGOUT, data || {})
                .then(() => { }, err => { }) // try/catch all
                .finally(data => {
                    // Clear auth user
                    this._store.dispatch(userAuthSet(null));
                    // Return, chain promise call
                    return resolve(data || { data: true });
                });
        });
    }

    dataList(_opts = {}) {
        // Get, format input(s)
        let opts = Object.assign({}, _opts);

        return new Promise((resolve) => {
            let dataList = super.dataList([], opts);
            let { filters = {} } = opts;
            if (Object.keys(filters).length) {
                dataList = dataList.filter((item, idx) => {
                    let rtn = true;
                    // Filter by: fullname or tel?
                    if (filters.fullname_or_tel) {
                        rtn = false;
                        let fnOrTelLC = filters.fullname_or_tel.toString().toLowerCase();
                        let fnLC = item.fullname().toString().toLowerCase();
                        let telLC = item.tel.toString().toLowerCase();
                        if ((fnLC.indexOf(fnOrTelLC) >= 0)
                            || (telLC.indexOf(fnOrTelLC) >= 0)
                        ) {
                            rtn = true;
                        }
                    }
                    return rtn;
                });
            }
            setTimeout(() => {
                resolve(dataList);
            }, 1e3);
        });
    }

    findOne(_opts = {}) {
        let dataList = this.dataList(_opts);
        return dataList[0] || null;
    }

    list(_opts = {}) {
        let opts = Object.assign({

        }, _opts);
        return this._api.get(_static.API_USER_LIST, opts);
    }

    init(_data = {}) {
        let data = Object.assign({}, _data);
        return this._api.post(_static.API_USER_INIT, data);
    }

    getOptions(_opts = {}) {
        let opts = Object.assign({}, _opts);
        return this._api.get(_static.API_USER_OPTS, opts);
    }

    getOptionsFull(_opts = {}) {
        let apiOpts = Object.assign({
            itemsPerPage: _static._MAX_ITEMS_PER_PAGE
        }, _opts);
        let opts = Object.assign({}, apiOpts['_opts']);
        delete apiOpts['_opts'];

        return this.list(apiOpts)
            .then(({ items }) => {
                return opts['raw'] ? items : items.map(({ full_name: name, user_id: id, user_name }) => ({ name, id, user_name }));
            });
    }

    create(_data = {}) {
        let data = Object.assign({}, this.fillable(), _data);
        return this._api.post(_static.API_USER_CREATE, data);
    }


    read(id, _data = {}) {
        let data = Object.assign({}, _data);
        return this._api.get(_static.API_USER_READ.replace(':id', id), data)
            .then((data) => new UserEntity(data))
            ;
    }


    update(id, _data = {}) {
        let data = Object.assign({}, _data);
        return this._api.put(_static.API_USER_UPDATE.replace(':id', id), data);
    }

    delete(id, _data = {}) {
        let data = Object.assign({}, _data);
        return this._api.delete(_static.API_USER_DELETE.replace(':id', id), data);
    }

    changePassword(id, _data = {}) {
        let data = Object.assign({}, _data);
        return this._api.put(_static.API_USER_CHANGE_PASSWORD.replace(':id', id), data);
    }

    changePasswordUser(id, _data = {}) {
        let data = Object.assign({}, _data);
        return this._api.put(_static.API_CHANGE_PASSWORD_USER.replace(':id', id), data);
    }

    changeImageUser(id, _data = {}) {
        let data = Object.assign({}, _data);
        return this._api.put(_static.API_CHANGE_IMAGE_USER.replace(':id', id), data);
    }

    listDivison(_opts = {}) {
        let opts = Object.assign({

        }, _opts);
        return this._api.get(_static.API_USER_LIST_DIVISON, opts);
    }
}
const _static = window._globModelUser = UserModel;

_static._apiStatic
    .addEventListener('beforeRequest', (event) => {
        let { args: { config } } = event;
        let { headers = {} } = config;
        let { name: hAuthName, value: hAuthValue } = _static.buildAuthHeader();
        if (hAuthName && hAuthValue) {
            headers[hAuthName] = hAuthValue;
        }
        Object.assign(config, { headers });
    })
    .addEventListener('request', (event) => {
        let { args: { /* config, */ incomming } } = event;
        //
        incomming.then(apiData => {
            delete apiData._;
            return apiData;
        })
    })
    .addEventListener('beforeRefreshToken', (event) => {
        let { args: authData } = event;
        let userAuth = _static.getUserAuthStatic();
        Object.assign(authData, userAuth);
    })
    .addEventListener('afterRefreshToken', (event) => {
        let { args: authData } = event;
        _static.storeUserAuthStatic(authData);
    });