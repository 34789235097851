import {applyMiddleware, createStore} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import stateReconciler from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import {reducers, noPersist} from './reducers';
import thunk from 'redux-thunk';

// Build the middleware for intercepting and dispatching navigation actions
const myMiddlewares = [];
const getMiddleware = () => {
  if (process.env.NODE_ENV === 'production') {
    return applyMiddleware(...myMiddlewares, thunk);
  } else {
    // Enable additional logging in non-production environments.
    return applyMiddleware(...myMiddlewares, thunk);
  }
};

export const store = createStore(
  persistReducer(
    {
      key: 'CMSXCELv1.0.0',
      storage,
      stateReconciler,
      blacklist: Object.entries(noPersist).map(([key]) => key),
    },
    reducers,
  ),
  composeWithDevTools(getMiddleware()),
);

export const persistor = persistStore(store);
window.persistor = persistor;
